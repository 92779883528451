import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 500.000000 500.000000" {...props}>
      <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#faf7f3" stroke="none">
<path d="M2430 4580 l0 -420 70 0 70 0 0 420 0 420 -70 0 -70 0 0 -420z"/>
<path d="M1242 4669 l-53 -31 19 -32 c10 -17 105 -183 212 -368 l194 -337 58
31 c32 17 56 33 54 37 -29 58 -421 731 -425 731 -3 0 -30 -14 -59 -31z"/>
<path d="M3485 4338 c-115 -200 -210 -366 -211 -369 -2 -4 22 -20 54 -37 l58
-31 194 337 c107 185 202 351 213 369 l18 31 -54 31 c-30 17 -57 31 -59 31 -3
0 -99 -163 -213 -362z"/>
<path d="M2267 3521 c-192 -390 -203 -411 -232 -416 -16 -3 -219 -32 -450 -66
-231 -33 -422 -63 -425 -65 -2 -3 49 -58 115 -122 66 -65 214 -209 328 -321
l209 -203 -77 -445 c-42 -245 -74 -447 -71 -450 5 -5 263 127 711 363 l100 53
185 -96 c102 -53 277 -145 389 -204 111 -60 212 -111 223 -115 19 -6 20 -3 15
22 -3 16 -38 219 -78 451 l-72 423 239 232 c131 128 279 272 329 320 49 48 87
89 85 91 -3 3 -194 33 -425 66 -231 34 -434 63 -451 66 -29 5 -38 21 -230 411
-109 223 -202 408 -207 411 -4 2 -99 -180 -210 -406z m560 -523 c16 -3 174
-27 353 -53 179 -26 331 -49 339 -51 10 -3 -80 -98 -249 -262 l-264 -258 62
-359 c34 -197 60 -361 57 -363 -3 -3 -118 55 -320 163 -33 18 -121 64 -195
103 l-136 71 -323 -170 c-178 -94 -325 -168 -328 -166 -3 4 27 188 104 632
l15 90 -263 257 c-176 171 -259 258 -249 261 8 3 159 26 335 51 176 26 334 49
351 52 29 5 38 22 195 336 l164 330 162 -329 c146 -294 166 -329 190 -335z"/>
<path d="M331 3755 c-17 -30 -31 -57 -31 -59 0 -2 19 -15 43 -28 23 -13 166
-96 317 -183 151 -87 297 -171 324 -186 l50 -28 33 56 c19 30 33 57 31 58 -4
4 -683 396 -715 413 -19 11 -24 7 -52 -43z"/>
<path d="M4270 3599 c-200 -115 -366 -212 -368 -213 -1 -2 12 -29 31 -59 l33
-56 50 28 c27 15 173 99 324 186 151 87 294 170 318 183 23 13 42 26 42 28 0
5 -61 114 -63 114 -1 0 -166 -95 -367 -211z"/>
<path d="M0 2500 l0 -60 420 0 420 0 0 60 0 60 -420 0 -420 0 0 -60z"/>
<path d="M4160 2500 l0 -60 420 0 420 0 0 60 0 60 -420 0 -420 0 0 -60z"/>
<path d="M984 1701 c-27 -15 -173 -99 -324 -186 -151 -87 -294 -170 -317 -183
-24 -13 -43 -26 -43 -28 0 -2 14 -29 31 -59 28 -50 33 -54 52 -43 32 17 711
409 715 413 2 1 -12 28 -31 58 l-33 56 -50 -28z"/>
<path d="M3931 1673 c-17 -31 -30 -57 -29 -59 4 -4 679 -394 710 -410 26 -13
27 -13 58 42 17 31 28 60 23 64 -4 4 -120 71 -258 150 -137 79 -286 164 -330
190 -44 26 -94 55 -111 63 l-31 16 -32 -56z"/>
<path d="M1420 762 c-107 -185 -202 -351 -213 -369 l-18 -31 56 -32 c31 -18
58 -30 61 -27 3 2 99 168 215 368 154 267 206 365 197 371 -7 5 -33 20 -59 33
l-45 23 -194 -336z"/>
<path d="M3330 1069 c-29 -16 -55 -31 -57 -32 -3 -4 409 -722 420 -734 3 -3
31 9 62 27 l56 32 -24 41 c-14 23 -109 188 -212 367 -103 179 -188 326 -189
327 -1 1 -26 -11 -56 -28z"/>
<path d="M2430 420 l0 -420 70 0 70 0 0 420 0 420 -70 0 -70 0 0 -420z"/>
</g>

      
          </Svg>
  );
};

export default Icon;
