import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (

    <Svg viewBox="0 0 512.000000 512.000000" {...props}>
<g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
fill="#faf7f3" stroke="none">
<path d="M2337 4900 c-369 -66 -654 -337 -744 -708 -25 -105 -23 -295 6 -407
28 -111 93 -247 160 -339 28 -38 51 -74 51 -81 0 -18 -34 -45 -57 -45 -11 0
-80 33 -154 74 -74 40 -204 112 -289 158 -293 161 -364 195 -441 208 -61 10
-81 10 -136 -4 -35 -8 -79 -23 -98 -32 -54 -25 -144 -120 -172 -180 -49 -107
-39 -243 24 -351 47 -80 113 -125 497 -332 204 -110 419 -226 478 -258 l108
-58 0 -228 c0 -231 4 -257 39 -284 25 -20 66 -15 89 10 21 23 22 30 22 298 0
205 -3 279 -12 291 -7 9 -147 89 -310 178 -164 89 -298 165 -298 169 0 4 35
36 77 71 43 35 121 106 175 158 l96 94 125 -66 c111 -59 130 -66 181 -66 108
0 180 62 201 171 10 54 -10 109 -66 185 -161 217 -203 462 -124 711 36 115 88
196 190 298 100 100 189 154 325 196 62 20 96 24 200 23 149 0 214 -15 349
-81 81 -38 108 -58 185 -137 103 -103 155 -184 192 -301 79 -248 39 -485 -119
-698 -51 -70 -76 -123 -77 -166 -1 -87 73 -181 156 -199 68 -15 102 -5 239 68
l117 62 97 -93 c53 -52 130 -123 171 -158 41 -35 75 -68 75 -73 0 -5 -129 -80
-288 -167 -158 -86 -296 -165 -307 -174 -20 -16 -20 -31 -20 -599 l0 -583 175
-199 c96 -109 175 -200 175 -202 0 -1 -20 -14 -45 -29 -25 -14 -100 -70 -168
-124 -67 -54 -134 -105 -148 -115 l-27 -17 -218 231 c-291 308 -339 337 -536
328 -84 -4 -106 -9 -165 -38 -58 -28 -90 -56 -213 -184 -80 -83 -186 -193
-235 -244 l-91 -94 -112 89 c-62 48 -148 113 -192 144 l-79 56 174 199 175
198 0 148 c0 135 -2 150 -20 168 -25 25 -71 26 -101 1 -22 -18 -24 -27 -27
-138 l-4 -119 -175 -200 c-696 -791 -648 -724 -648 -889 0 -89 3 -107 29 -163
37 -80 122 -161 203 -193 79 -31 194 -31 278 1 76 29 146 97 803 792 288 304
306 318 430 318 113 0 145 -22 367 -255 774 -815 765 -806 835 -839 59 -28 74
-31 165 -31 93 0 105 2 166 32 125 62 199 174 207 314 5 89 -13 164 -55 226
-26 39 -159 192 -592 683 l-181 205 0 515 0 515 188 101 c103 56 302 163 442
239 312 169 380 212 421 267 91 124 109 279 47 407 -27 56 -110 142 -164 169
-99 50 -226 53 -325 9 -49 -21 -285 -149 -643 -346 -71 -39 -139 -71 -152 -71
-20 0 -54 32 -54 51 0 3 23 37 51 75 196 264 240 599 119 897 -106 258 -321
451 -596 533 -72 21 -114 27 -219 30 -71 1 -152 -1 -178 -6z m1907 -1301 c58
-21 105 -68 126 -125 28 -74 3 -191 -53 -242 l-25 -23 -110 108 c-115 113
-201 228 -185 244 19 19 128 59 160 59 18 0 57 -9 87 -21z m-3316 -14 c28 -13
52 -27 52 -30 0 -24 -92 -138 -188 -233 l-114 -114 -25 24 c-88 82 -89 233 -1
315 69 65 185 81 276 38z m284 -156 c51 -28 94 -52 96 -54 5 -4 -347 -315
-356 -315 -12 0 -121 60 -127 70 -3 5 16 26 42 47 52 43 173 184 218 255 17
27 31 48 32 48 2 0 45 -23 95 -51z m2673 5 c36 -62 163 -211 219 -257 25 -21
46 -41 46 -45 0 -8 -120 -73 -133 -72 -8 0 -52 38 -261 229 -45 40 -81 78 -81
83 0 9 159 106 177 107 3 1 18 -19 33 -45z m-2540 -2547 c33 -22 115 -83 183
-136 l123 -95 -60 -60 -60 -59 -38 28 c-21 16 -78 61 -128 100 -49 39 -116 89
-147 111 l-57 41 52 60 c29 33 57 58 62 55 6 -3 37 -23 70 -45z m2414 -106
c-29 -20 -86 -63 -128 -97 -42 -33 -102 -80 -134 -104 l-58 -43 -60 59 -59 59
121 95 c67 52 153 116 191 142 l70 46 55 -61 54 -60 -52 -36z m-2639 -125 c25
-16 105 -76 178 -132 l132 -103 -87 -91 c-110 -116 -142 -133 -239 -128 -61 3
-79 8 -115 33 -86 61 -123 187 -81 278 23 51 142 188 156 180 6 -4 31 -20 56
-37z m2870 -41 c67 -77 90 -125 90 -195 0 -117 -89 -209 -211 -218 -88 -6
-147 26 -248 136 -73 81 -78 89 -61 100 11 6 70 51 132 100 168 131 220 164
234 149 7 -6 35 -39 64 -72z"/>
<path d="M2099 4364 c-52 -16 -136 -85 -145 -119 -7 -27 18 -80 41 -89 32 -12
53 -6 96 29 73 58 114 57 187 -6 40 -35 75 -37 107 -4 39 38 33 71 -24 126
-75 72 -163 94 -262 63z"/>
<path d="M2719 4367 c-52 -15 -96 -44 -134 -89 -31 -37 -29 -82 6 -110 31 -26
64 -22 103 13 43 37 86 50 127 39 16 -5 46 -23 65 -39 19 -17 45 -31 58 -31
33 0 65 29 72 66 5 27 0 38 -31 72 -66 74 -172 105 -266 79z"/>
<path d="M2160 3843 c-108 -39 -36 -173 137 -255 125 -59 260 -57 388 6 73 36
160 112 176 153 17 46 -20 96 -71 96 -21 0 -45 -15 -89 -55 -69 -65 -127 -88
-216 -88 -88 0 -147 23 -213 85 -58 53 -85 67 -112 58z"/>
<path d="M2395 3141 c-131 -60 -176 -223 -93 -338 128 -179 408 -91 408 128 0
98 -60 183 -153 218 -43 16 -117 13 -162 -8z m140 -156 c48 -47 17 -125 -51
-125 -38 0 -74 38 -74 77 0 32 42 73 75 73 14 0 37 -11 50 -25z"/>
<path d="M2379 2522 c-79 -41 -114 -102 -114 -197 0 -96 35 -156 115 -198 49
-26 63 -28 120 -24 78 5 133 36 177 100 25 37 28 51 28 122 0 74 -2 84 -32
127 -67 95 -190 125 -294 70z m156 -147 c14 -13 25 -36 25 -50 0 -14 -11 -37
-25 -50 -13 -14 -36 -25 -50 -25 -14 0 -37 11 -50 25 -14 13 -25 36 -25 50 0
14 11 37 25 50 13 14 36 25 50 25 14 0 37 -11 50 -25z"/>
<path d="M2400 1927 c-93 -50 -129 -100 -137 -188 -20 -214 232 -332 384 -181
70 70 84 200 30 281 -43 64 -99 95 -182 99 -46 2 -79 -2 -95 -11z m137 -159
c14 -13 23 -33 23 -50 0 -36 -40 -78 -75 -78 -14 0 -37 11 -50 25 -69 68 31
170 102 103z"/>
</g>
    </Svg>
  );
};

export default Icon;
