import { MenuEntry } from 'uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade (SpookySwap)',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://spookyswap.finance/swap?outputCurrency=0xD3111Fb8BDf936B11fFC9eba3b597BeA21e72724',
      },
      {
        label: 'Liquidity',
        href: 'https://spookyswap.finance/add/FTM/0xD3111Fb8BDf936B11fFC9eba3b597BeA21e72724',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/pools',
  },
  // {
  //   label: 'Community Pools',
  //   icon: 'GroupsIcon',
  //   href: '/community',
  // },
  // {
  //   label: 'Pools',
  //   icon: 'PoolIcon',
  //   href: '/pools',
  // },
  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   href: '/nft',
  // },
  {
    label: 'Dividends',
    icon: 'DivIcon',
    href: '/dividends',
  },

    {
      label: 'Vaults',
      icon: 'VaultIcon',
        items: [
        {
        label: 'Yield Wolf',
        href: 'https://yieldwolf.finance/fantom/xmas-past',
        },
      ],
    },
  {
    label: 'Earn',
    icon: 'EarnIcon',
    items: [
      {
        label: 'Bouje Finance',
        href: 'https://www.bouje.finance/pools',
      },
      {
        label: 'Olive Cash',
        href: 'https://fantom.olive.cash/farms',
      },
      {
        label: 'Cougar Swap',
        href: 'https://fantomapp.cougarswap.io/nests',
      },
    ],
  },
  {
    label: 'Tools',
    icon: 'ToolsIcon',
    items: [
      {
        label: 'VFat',
        href: 'https://vfat.tools/fantom/xmaspast/',
      },
      {
        label: 'FarmScan',
        href: 'https://ftm.farmscan.io/address/0x138c4db5d4ab76556769e4ea09bce1d452c2996f#mainpup',
      },
      {
        label: '0xTracker',
        href: 'https://0xtracker.app/farms',
      },
      {
        label: 'FarmAssist',
        href: 'https://www.farmassist.app/pools-and-farms',
      },
    ],
  },
  {
    label: 'Charts',
    icon: 'InfoIcon',
    items: [
      {
        label: 'CoinMarketCap',
        href: 'https://coinmarketcap.com/currencies/xmas-past/',
      },
      {
        label: 'Kek.tools',
        href: 'https://kek.tools/t/0xd3111fb8bdf936b11ffc9eba3b597bea21e72724?pair=0x73b019d2b6fd28d85eead4e85909d69cc0472d5f',
      },
      {
        label: 'Zoo.coin',
        href: 'https://charts.zoocoin.cash/charts?exchange=SpookySwap&pair=0x9665067DceF6a88d2dCf042ee25A2d98a2DDF8D6-inverted',
      },
      {
        label: 'Spooky Info',
        href: 'https://info.spookyswap.finance/token/0xD3111Fb8BDf936B11fFC9eba3b597BeA21e72724',
      },
      {
        label: 'DexTools',
        href: 'https://www.dextools.io/app/fantom/pair-explorer/0x73b019d2b6fd28d85eead4e85909d69cc0472d5f',
      },
      {
        label: 'Nomics',
        href: 'https://nomics.com/assets/XPAST-XPAST?utm_source=twitter&utm_medium=bot&utm_campaign=new_asset&utm_content=xpast&d=1639343257',
      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      // {
      //   label: 'Github',
      //   href: 'https://github.com/polydaedalus',
      // },
      {
        label: 'Docs',
        href: 'https://docs.xmas-past.com',
      },
      {
        label: 'Dapp',
        href: 'https://www.dapp.com/app/xmas-past',
      },
      {
        label: 'DappRadar',
        href: 'https://dappradar.com/other/defi/xmas-past',
      },
      {
        label: 'ApeOClock',
        href: 'https://www.apeoclock.com/launch/xmas-past-farm-launch/',
      },
      {
        label: 'DefiLlama',
        href: 'https://defillama.com/protocol/xmas-past',
      },
     // {
     //   label: 'Vfat.tools',
     //   href: 'https://vfat.tools/polygon/platinumfinance/',
     // }
    ],
  },
]

export default config
