import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 500.000000 500.000000" {...props}>
<g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#faf7f3" stroke="none">
<path d="M2473 4924 c-3 -9 -32 -96 -63 -193 l-56 -176 -207 -3 c-164 -2 -207
-5 -207 -16 0 -10 104 -87 328 -241 2 -2 -125 -356 -130 -360 -2 -1 -181 92
-398 206 -217 115 -419 217 -449 225 -141 42 -310 -36 -378 -174 l-28 -57 -3
-593 c-2 -394 1 -606 8 -632 23 -87 119 -169 214 -185 l41 -7 -45 -39 c-59
-53 -208 -153 -284 -191 -162 -82 -359 -139 -551 -159 -67 -7 -124 -19 -147
-30 -70 -36 -118 -118 -118 -203 0 -89 250 -495 497 -810 l70 -88 -33 -50
c-50 -74 -67 -143 -62 -252 4 -78 9 -100 37 -155 41 -79 109 -147 187 -185 49
-25 75 -30 150 -34 109 -5 178 12 251 62 l49 34 115 -89 c219 -171 472 -338
659 -435 62 -32 83 -38 135 -37 72 1 132 27 169 75 36 48 42 71 55 193 13 120
45 258 86 375 30 82 124 270 136 270 14 0 107 -194 143 -298 41 -117 61 -209
80 -371 12 -96 18 -120 43 -155 41 -59 100 -88 177 -89 54 -1 73 5 136 37 187
97 440 264 660 435 l114 89 44 -30 c76 -52 145 -71 247 -66 112 5 181 34 260
109 81 76 118 157 123 269 5 105 -12 175 -62 248 l-34 51 74 92 c164 206 339
471 441 666 60 117 66 159 32 233 -43 96 -91 123 -244 139 -192 20 -389 77
-551 159 -75 38 -224 138 -284 190 l-45 39 47 10 c99 21 185 98 208 184 7 25
10 248 8 631 -3 584 -3 594 -25 640 -64 140 -228 223 -371 189 -42 -11 -351
-168 -778 -397 l-101 -54 -68 186 c-42 114 -64 189 -58 193 92 58 323 229 323
238 0 10 -46 13 -208 13 l-208 0 -60 192 c-57 183 -76 221 -91 182z m59 -339
l34 -110 128 -5 128 -5 -101 -70 c-55 -38 -101 -75 -101 -81 0 -6 18 -60 40
-119 23 -59 39 -109 37 -111 -2 -2 -48 28 -103 66 -54 39 -105 68 -112 65 -7
-3 -56 -34 -108 -71 -52 -36 -94 -62 -94 -57 0 4 18 59 41 121 23 62 40 114
38 116 -2 1 -49 34 -104 72 l-100 69 127 3 127 3 37 120 c21 66 41 116 45 112
4 -5 23 -57 41 -118z m-1204 -315 c55 -26 861 -448 871 -456 2 -2 -11 -21 -28
-43 -115 -144 -131 -347 -41 -494 17 -27 30 -50 30 -51 0 -2 -55 -6 -122 -9
-276 -13 -512 -117 -716 -315 -83 -81 -121 -102 -179 -102 -76 1 -162 62 -181
128 -4 15 -7 284 -7 597 0 653 -3 629 85 708 84 75 181 88 288 37z m2544 18
c53 -16 121 -78 150 -136 l23 -47 3 -549 c2 -367 -1 -566 -8 -600 -27 -125
-153 -191 -264 -136 -23 11 -72 52 -111 91 -184 186 -431 294 -702 306 -68 3
-123 8 -123 10 0 2 11 20 25 41 73 112 83 286 23 407 -11 22 -36 62 -55 88
-20 26 -34 49 -32 51 10 8 797 421 860 451 80 38 139 45 211 23z m-1219 -272
l156 -110 -42 -24 c-38 -22 -44 -22 -67 -9 -103 61 -297 61 -401 0 -24 -15
-29 -14 -73 12 l-47 27 23 17 c189 135 284 200 288 198 3 -1 76 -51 163 -111z
m-68 -181 c116 -31 202 -109 246 -223 30 -81 24 -194 -15 -274 -43 -86 -97
-132 -180 -152 -38 -9 -84 -23 -102 -31 -29 -12 -39 -11 -81 5 -26 10 -74 24
-106 31 -50 11 -65 20 -107 66 -69 76 -93 144 -88 253 3 63 10 97 28 130 52
103 128 167 230 194 72 19 103 19 175 1z m-298 -710 c125 -27 123 -20 22 -87
-216 -143 -376 -368 -447 -628 -22 -84 -26 -115 -26 -265 -1 -187 14 -269 74
-416 63 -152 115 -224 297 -409 95 -96 189 -197 209 -224 l37 -48 -22 -37
c-48 -79 -131 -261 -160 -351 -33 -103 -55 -215 -71 -354 -10 -96 -24 -126
-73 -157 -58 -37 -113 -23 -265 65 -702 405 -1288 990 -1698 1696 -75 129 -79
138 -79 197 0 52 4 66 25 88 38 41 53 46 183 61 142 17 283 51 405 98 229 88
377 189 584 397 86 87 185 179 220 204 220 163 520 228 785 170z m810 4 c136
-25 300 -97 411 -181 30 -22 124 -111 210 -197 175 -175 267 -245 439 -333
178 -90 373 -146 563 -163 99 -9 131 -19 165 -55 20 -21 29 -42 33 -78 5 -55
-6 -81 -115 -266 -202 -341 -436 -638 -735 -932 -296 -291 -590 -515 -953
-725 -102 -59 -114 -63 -172 -64 -55 0 -65 3 -92 29 -37 35 -47 67 -56 166
-15 174 -64 354 -141 519 -89 190 -190 326 -386 517 -203 197 -284 331 -340
562 -29 121 -29 303 0 424 58 239 174 428 349 568 233 188 528 263 820 209z
m-2247 -2231 c258 -258 246 -237 151 -280 -95 -44 -214 -32 -313 31 -112 71
-172 227 -137 356 10 36 55 125 63 125 2 0 108 -104 236 -232z m3575 166 c39
-78 42 -192 7 -268 -37 -80 -85 -131 -160 -168 -59 -29 -76 -33 -147 -33 -61
0 -91 5 -125 22 -97 45 -108 22 147 280 125 127 233 230 238 228 6 -1 23 -28
40 -61z"/>
</g>
</Svg>

  );
};

export default Icon;
