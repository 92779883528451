import { Colors } from "./types";

export const baseColors = {
    failure: "#DD5252",
  primary: "#12110F",
  primaryBright: "#7D94B5",
  primaryDark: "#7D94B5",
  secondary: "#7D94B5",
  success: "#31D0AA",
  warning: "#D199AE",
};

export const additionalColors = {
 binance: "#638E91",
  overlay: "#524B4F",
};

export const lightColors: Colors = {
  ...baseColors,
  ...additionalColors,
  background: "#222629E6",
  backgroundDisabled: "#3c3742",
  backgroundAlt: "rgba(32, 38, 48, 0.8)", // farmcards
  cardBorder: "#E9EAEB",
  contrast: "#191325",
  dropdown: "rgba(255, 255, 255, 0.5)",
  dropdownDeep: "#E2E6ED",
  invertedContrast: "#faf7f3",
  input: "#1F242D",           // input output color, should be same as tertiary
  inputSecondary: "#DFE7F2",
  tertiary: "#1F242D",      // menu highlight hover colour
  text: "#faf7f3",
  textDisabled: "#666171", 
  textSubtle: "#faf7f3",
  disabled: "#E9EAEB",

  
  gradients: {
    bubblegum: "linear-gradient(139.73deg, #C1D0F4 0%, #DFE7F2 100%)",
    inverseBubblegum: "linear-gradient(139.73deg, #DFE7F2 0%, #C1D0F4 100%)",
    cardHeader: "linear-gradient(111.68deg, #F4F4F9 0%, #E2E6ED 100%)",
    blue: "linear-gradient(180deg, #CAEFED 0%, #C3D1E2 100%)",
    violet: "linear-gradient(180deg, #CAEFED 0%, #C3D1E2 100%)",
    violetAlt: "linear-gradient(180deg, #CAEFED 0%, #C3D1E2 100%)",
    gold: "linear-gradient(180deg, #71FFFF 0%, #F2ACA2 100%)",
  },
};

export const darkColors: Colors = {
  ...baseColors,
  ...additionalColors,
  secondary: "#9AEBED",
  background: "#17172d",
  backgroundDisabled: "#3c3742",
  backgroundAlt: "#030323",
  cardBorder: "#38434C",
  contrast: "#191326",
  dropdown: "#1B1E1E",
  dropdownDeep: "#0E1214",
  invertedContrast: "#191326",
  input: "#3B3F56",
  inputSecondary: "#222B51",
  primaryDark: "#e3e1e1",
  tertiary: "#353547",
  text: "#E1E7F9",
  textDisabled: "#BDC2C4",
  textSubtle: "#B9CEEA",
  disabled: "#4A5160",
  gradients: {
    bubblegum: "linear-gradient(139.73deg, #31475B 0%, #272B35 100%)",
    inverseBubblegum: "linear-gradient(139.73deg, #272B35 0%, #31475B 100%)",
    cardHeader: "linear-gradient(166.77deg, #31475B 0%, #3A3045 100%)",
    blue: "linear-gradient(180deg, #31475B 0%, #4A5160 100%)",
    violet: "linear-gradient(180deg, #31475B 0%, #31475B 100%)",
    violetAlt: "linear-gradient(180deg, #31475B 0%, #31475B 100%)",
    gold: "linear-gradient(180deg, #71FFFF 0%, #F2ACA2 100%)",
  },
};