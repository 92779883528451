import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 500.000000 500.000000" {...props}>
<g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#faf7f3" stroke="none">
<path d="M2375 4983 c-308 -15 -522 -104 -542 -226 -27 -170 229 -277 665
-277 319 0 568 68 632 172 23 39 26 107 6 146 -52 99 -215 159 -490 182 -71 5
-147 9 -170 8 -23 -1 -68 -3 -101 -5z m395 -207 c47 -9 103 -22 125 -29 l40
-14 -65 -21 c-111 -34 -231 -45 -436 -39 -147 4 -208 10 -274 26 -47 12 -89
26 -93 30 -14 14 156 54 283 65 84 7 335 -4 420 -18z"/>
<path d="M2352 4339 c-139 -24 -279 -106 -394 -228 -126 -134 -188 -292 -188
-480 0 -167 40 -294 134 -425 41 -58 116 -134 163 -167 20 -14 21 -18 10 -56
-7 -23 -15 -45 -19 -48 -4 -4 -26 8 -50 28 -140 113 -380 159 -554 106 -208
-63 -369 -219 -459 -444 -115 -291 -407 -875 -830 -1665 -84 -157 -154 -294
-155 -305 -4 -41 1 -57 26 -80 25 -23 35 -25 123 -25 168 0 293 48 411 157 81
75 143 163 167 239 l17 54 76 0 c200 0 413 117 519 285 23 35 41 68 41 73 0 6
210 4 217 -2 2 -1 -65 -237 -147 -523 -161 -560 -159 -549 -106 -590 28 -22
194 -69 381 -107 577 -119 1225 -96 1785 64 112 32 150 58 150 105 0 17 -270
973 -294 1040 -2 7 39 11 118 11 l123 0 28 -54 c16 -31 59 -85 95 -122 113
-114 319 -199 457 -188 l53 5 15 -46 c24 -69 55 -124 104 -183 130 -155 284
-228 481 -228 89 0 92 1 122 30 26 27 30 36 25 68 -3 20 -63 145 -134 277
-324 602 -670 1286 -782 1545 -135 313 -187 397 -299 485 -68 54 -176 110
-252 130 -171 46 -399 -6 -549 -125 -29 -23 -54 -40 -56 -38 -1 1 -9 25 -17
53 l-14 50 96 95 c52 52 110 120 128 152 97 165 123 390 66 578 -68 227 -268
423 -494 486 -68 18 -266 26 -338 13z m313 -205 c152 -52 289 -192 336 -344
23 -74 27 -214 9 -293 -25 -115 -125 -252 -235 -322 -207 -133 -480 -103 -654
72 -115 115 -161 224 -161 378 0 152 51 277 158 382 67 67 172 126 252 143 66
13 236 5 295 -16z m-175 -1227 c62 0 131 6 167 15 57 14 63 14 67 -1 3 -9 136
-470 296 -1026 160 -555 293 -1018 296 -1027 6 -20 -26 -30 -186 -59 -409 -75
-850 -76 -1257 -4 -172 31 -213 42 -208 58 4 12 410 1426 525 1827 34 118 63
222 66 231 4 15 10 15 67 1 34 -8 109 -15 167 -15z m-710 -32 c96 -34 210
-132 210 -179 0 -12 -72 -268 -159 -571 l-158 -550 -39 -12 c-58 -17 -177 -15
-250 4 -62 17 -64 17 -91 -3 -29 -22 -37 -34 -78 -125 -81 -180 -290 -283
-484 -238 -53 12 -105 -2 -126 -35 -8 -12 -19 -47 -25 -77 -24 -129 -118 -252
-237 -311 -35 -17 -65 -29 -67 -27 -3 2 47 101 110 219 356 666 607 1167 742
1483 80 186 111 240 174 308 35 38 122 97 175 118 76 31 211 29 303 -4z m1781
-12 c77 -38 165 -115 207 -180 17 -26 58 -113 92 -192 132 -309 420 -889 768
-1541 60 -113 108 -207 106 -208 -9 -12 -142 67 -182 107 -67 67 -98 124 -125
227 -20 79 -25 90 -56 108 -30 17 -39 18 -96 8 -88 -17 -133 -15 -207 8 -124
38 -222 119 -270 223 -74 160 -74 160 -207 133 -98 -20 -164 -17 -240 12 l-44
17 -158 545 -157 545 21 30 c56 83 138 144 235 176 43 15 80 18 153 16 84 -2
103 -6 160 -34z m-1701 -2247 c235 -40 360 -50 630 -50 140 0 307 5 370 12
132 15 380 56 457 76 29 8 55 11 57 8 2 -4 22 -69 44 -144 l40 -138 -37 -9
c-170 -45 -314 -72 -506 -98 -164 -21 -657 -24 -815 -5 -203 25 -542 92 -563
112 -5 4 1 41 13 81 12 41 30 104 41 141 l19 66 53 -14 c28 -7 117 -24 197
-38z"/>
</g>
    </Svg>
  );
};

export default Icon;
