import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 500.000000 500.000000" {...props}>
<g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#faf7f3" stroke="none">
<path d="M1247 4990 c-330 -56 -575 -279 -677 -618 l-22 -73 30 -62 c82 -165
256 -310 435 -362 l57 -16 -54 -67 c-29 -37 -72 -96 -95 -132 -23 -36 -74 -94
-114 -129 -161 -143 -258 -311 -298 -510 -10 -51 -17 -64 -39 -76 -38 -20 -99
-90 -123 -144 -31 -66 -30 -171 2 -238 l23 -50 -20 -84 c-29 -117 -35 -211
-22 -306 20 -149 56 -260 119 -364 38 -63 38 -66 44 -189 11 -243 99 -452 255
-603 49 -48 49 -49 41 -93 -23 -122 51 -263 169 -321 49 -24 69 -28 143 -28
l86 0 48 -48 c112 -112 292 -204 465 -237 30 -6 99 -11 154 -11 95 -1 100 -2
154 -36 120 -77 268 -122 428 -130 180 -9 345 30 499 118 83 48 88 49 166 49
70 0 84 -3 113 -25 123 -94 284 -89 396 12 61 55 88 104 101 186 9 52 17 67
61 116 53 58 142 187 153 222 4 10 25 29 48 41 209 106 375 299 451 526 19 56
27 67 47 70 51 6 133 57 175 109 51 63 64 103 64 204 0 88 -29 158 -85 208
l-33 29 21 79 c16 59 21 109 21 208 1 162 -23 265 -96 414 -28 57 -52 118 -53
135 -7 105 -26 249 -40 300 -50 188 -155 358 -291 470 -42 34 -84 82 -113 128
-26 40 -69 99 -95 131 -26 32 -46 60 -44 62 2 2 40 17 86 34 150 54 292 171
375 307 50 83 53 102 27 186 -99 330 -356 558 -684 608 -74 11 -76 11 -193
-28 -239 -80 -483 -199 -698 -339 l-100 -66 -64 23 c-115 41 -270 37 -362 -10
l-36 -19 -49 35 c-194 140 -512 297 -764 378 -116 38 -118 38 -193 26z m199
-190 c150 -51 465 -207 607 -299 84 -56 107 -75 107 -93 0 -13 -9 -75 -20
-138 -11 -63 -20 -116 -20 -117 0 0 -36 21 -81 48 -140 84 -321 142 -548 176
-92 14 -182 18 -433 18 -175 0 -318 1 -318 3 0 1 15 33 34 70 92 183 240 306
426 356 85 23 118 19 246 -24z m2309 25 c33 -8 97 -34 143 -56 105 -51 208
-151 269 -260 70 -127 91 -115 -195 -111 -523 8 -846 -60 -1103 -231 l-26 -17
-22 132 c-12 73 -21 136 -21 139 0 13 221 151 342 214 143 74 232 114 378 166
117 42 149 46 235 24z m-1157 -390 c26 -11 42 -24 45 -38 16 -66 59 -358 55
-363 -4 -3 -32 -12 -65 -21 -81 -22 -231 -21 -311 2 -60 17 -64 20 -59 44 3
14 17 101 33 193 l27 167 41 15 c23 8 50 17 61 20 34 8 131 -3 173 -19z
m-1183 -205 c39 -5 85 -12 103 -15 l34 -6 -75 -39 c-76 -39 -178 -104 -230
-147 -34 -27 -77 -30 -162 -8 -102 27 -184 74 -260 150 l-70 70 65 7 c90 9
510 1 595 -12z m2720 11 l70 -7 -72 -71 c-78 -77 -156 -122 -258 -148 -98 -25
-122 -21 -195 35 -36 27 -109 74 -163 104 -72 39 -94 56 -80 59 20 6 181 25
253 30 84 6 375 5 445 -2z m-2135 -200 c0 -4 -24 -17 -52 -29 -85 -35 -176
-100 -274 -195 -105 -101 -185 -208 -264 -355 -30 -56 -58 -102 -61 -102 -3 0
-11 28 -17 62 -13 70 -42 108 -81 108 -14 -1 -77 -11 -138 -24 -62 -13 -113
-20 -113 -16 0 4 31 54 70 113 82 123 220 267 333 345 94 65 258 144 352 170
l70 20 88 -45 c48 -25 87 -48 87 -52z m1247 64 c182 -62 336 -158 479 -301 90
-90 234 -281 234 -311 0 -6 -48 0 -121 14 -67 14 -132 22 -145 19 -33 -9 -52
-41 -67 -117 l-13 -64 -56 108 c-138 263 -338 470 -540 557 -32 14 -58 27 -58
31 0 12 157 89 183 89 16 0 62 -11 104 -25z m-1005 -227 c88 -30 101 -32 238
-32 139 -1 149 1 240 32 114 40 141 38 255 -18 216 -107 408 -350 535 -675 28
-71 56 -141 63 -155 28 -56 117 -47 136 14 5 17 22 94 37 171 15 77 29 142 30
144 2 2 78 -11 170 -29 129 -26 171 -31 184 -22 15 9 22 6 42 -21 42 -56 67
-102 93 -171 l25 -66 -43 7 c-148 27 -278 -34 -345 -161 -23 -44 -27 -63 -27
-136 0 -73 4 -92 27 -135 92 -175 310 -227 461 -111 l45 34 21 -48 c34 -79 52
-176 52 -279 0 -131 -26 -251 -51 -236 -16 10 -119 -13 -167 -37 -159 -80
-210 -299 -103 -446 27 -38 94 -95 122 -104 17 -6 -49 -161 -101 -235 -68 -97
-164 -181 -272 -238 -86 -46 -92 -51 -121 -109 -34 -67 -102 -171 -118 -181
-6 -4 -28 9 -50 29 -72 65 -114 81 -215 81 -81 0 -95 -3 -143 -29 -102 -57
-153 -139 -160 -259 l-5 -77 -66 2 c-60 3 -70 0 -111 -28 -135 -94 -275 -138
-435 -138 -162 0 -319 48 -439 134 -43 31 -45 32 -177 34 -155 2 -224 18 -344
76 -81 39 -191 119 -201 145 -3 8 3 26 15 41 31 40 61 129 61 184 0 111 -73
225 -176 276 -98 48 -234 33 -321 -36 -25 -19 -27 -20 -46 -3 -31 29 -102 133
-137 202 -51 102 -71 197 -71 342 1 120 -1 127 -25 160 -45 59 -112 211 -128
291 -29 141 -15 348 22 333 41 -16 121 -23 167 -14 113 21 205 103 239 213 34
107 1 231 -81 307 -47 43 -114 78 -150 79 -34 0 -36 15 -13 87 25 78 61 152
102 209 27 38 32 41 56 32 21 -8 64 -3 185 22 88 17 160 30 162 28 2 -2 15
-64 30 -138 33 -170 41 -196 68 -215 60 -42 96 -9 151 135 148 393 369 661
618 750 71 26 82 25 190 -12z m2016 -988 c128 -78 72 -280 -77 -280 -86 0
-151 64 -151 150 0 85 65 150 150 150 27 0 58 -8 78 -20z m-3548 -77 c95 -54
93 -205 -2 -263 -63 -38 -156 -19 -198 40 -30 42 -38 116 -17 156 43 83 138
112 217 67z m3778 -1003 c115 -70 88 -244 -44 -279 -164 -43 -257 190 -112
279 43 26 113 26 156 0z m-3306 -855 c90 -67 79 -213 -19 -260 -134 -64 -273
76 -204 205 30 57 76 82 141 78 35 -3 66 -11 82 -23z m2308 -384 c95 -69 90
-204 -10 -260 -156 -87 -304 123 -174 246 19 17 44 34 56 36 38 8 102 -3 128
-22z"/>
<path d="M2020 3601 c-92 -30 -169 -95 -204 -170 -31 -68 -30 -199 3 -260 l23
-44 -23 -19 c-40 -31 -147 -89 -222 -120 -105 -44 -108 -48 -119 -168 -17
-187 -86 -391 -177 -524 -17 -24 -31 -53 -31 -63 0 -13 -14 -23 -48 -36 -256
-90 -272 -449 -25 -564 85 -39 171 -39 266 2 15 6 17 1 17 -48 0 -72 20 -96
109 -131 138 -54 326 -184 405 -280 l35 -43 -54 -22 c-70 -29 -147 -111 -170
-181 -49 -149 14 -306 152 -376 50 -25 69 -29 138 -28 96 0 154 23 214 83 60
60 84 113 89 201 5 98 -20 168 -84 232 l-46 46 48 9 c84 14 312 8 394 -11 159
-36 138 -43 251 77 54 58 122 122 149 142 l51 37 52 -23 c81 -36 164 -33 249
9 114 56 171 147 172 272 1 85 -20 142 -73 202 -17 20 -31 41 -31 47 0 27 63
173 111 256 29 50 52 101 52 115 0 14 -23 64 -51 110 -108 181 -151 331 -159
565 -7 187 -21 240 -84 305 -93 95 -245 124 -355 67 l-52 -26 -65 71 c-73 79
-63 77 -202 44 -38 -9 -127 -20 -196 -23 l-126 -6 -12 48 c-16 64 -32 93 -76
138 -54 55 -124 88 -203 92 -37 3 -78 1 -92 -4z m157 -171 c37 -22 73 -86 73
-127 0 -43 -41 -115 -77 -136 -44 -25 -114 -24 -157 3 -37 22 -76 90 -76 130
0 39 39 107 73 129 42 27 121 28 164 1z m673 -265 c43 -44 43 -45 32 -83 -17
-55 -15 -140 4 -196 22 -64 100 -147 166 -177 71 -31 174 -32 239 -2 l47 21
12 -62 c20 -108 59 -218 116 -333 l57 -113 -51 -99 c-27 -54 -58 -123 -67
-153 l-18 -56 -66 -4 c-92 -7 -161 -39 -220 -103 -75 -83 -98 -189 -64 -295
l17 -53 -34 -26 c-19 -14 -76 -66 -127 -114 l-91 -89 -95 16 c-124 21 -334 21
-454 -1 l-92 -16 -78 79 c-105 106 -195 172 -331 241 -78 41 -114 64 -116 78
-20 104 -26 174 -16 199 16 44 12 140 -10 199 -21 55 -83 128 -131 153 -15 7
-29 20 -32 28 -3 8 18 61 47 118 64 127 102 242 123 373 8 55 17 109 19 121 3
15 22 29 69 49 36 15 109 56 162 91 l96 64 55 -16 c31 -9 75 -14 101 -12 94 9
207 82 247 161 14 26 18 27 62 21 50 -6 259 11 308 26 53 16 69 11 114 -35z
m410 -47 c39 -27 70 -81 70 -123 0 -48 -40 -116 -80 -137 -45 -23 -106 -23
-150 1 -38 20 -80 89 -80 130 1 44 37 109 72 131 47 28 125 27 168 -2z m-1853
-1078 c36 -22 58 -58 68 -113 5 -27 2 -47 -15 -83 -29 -58 -70 -84 -134 -84
-117 0 -191 119 -137 220 40 76 145 105 218 60z m2013 -316 c114 -97 56 -274
-90 -274 -140 0 -203 171 -98 266 31 28 43 32 105 33 42 1 57 -4 83 -25z
m-1240 -766 c68 -47 88 -131 47 -202 -61 -108 -213 -104 -266 8 -12 23 -21 53
-21 66 0 39 39 107 73 129 45 29 123 28 167 -1z"/>
</g>
    </Svg>
  );
};

export default Icon;
