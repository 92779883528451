import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 500.000000 500.000000" {...props}>
<g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#faf7f3" stroke="none">
<path d="M2472 4993 c-7 -3 -42 -63 -77 -134 -36 -71 -68 -129 -73 -129 -4 0
-65 -9 -137 -20 -105 -16 -133 -24 -147 -41 -10 -12 -18 -29 -18 -38 0 -9 47
-61 105 -116 l105 -100 -24 -140 c-21 -124 -23 -143 -10 -162 8 -13 25 -23 37
-23 12 0 76 29 142 64 l120 63 128 -65 c110 -57 131 -65 155 -56 36 13 38 41
12 195 l-21 123 100 100 c79 77 101 105 101 126 0 15 -6 32 -13 38 -10 9 -248
52 -285 52 -5 0 -35 54 -66 120 -32 66 -64 127 -73 135 -14 15 -37 18 -61 8z
m73 -264 c22 -44 45 -85 51 -90 7 -5 41 -14 76 -19 34 -6 78 -13 97 -16 l34
-5 -73 -67 -73 -66 7 -65 c3 -37 9 -85 12 -109 l7 -44 -81 41 c-45 23 -91 41
-102 41 -11 0 -56 -18 -100 -40 -44 -22 -81 -40 -83 -40 -2 0 2 33 9 73 23
128 23 128 -56 203 -39 37 -68 69 -66 71 2 2 45 10 96 18 50 8 97 18 103 24 7
5 30 46 52 90 21 45 42 81 45 81 3 0 24 -36 45 -81z"/>
<path d="M2473 4074 c-6 -3 -42 -51 -79 -107 -89 -135 -282 -329 -414 -416
-160 -106 -321 -176 -503 -222 -66 -16 -92 -28 -103 -45 -22 -33 -5 -60 96
-146 106 -91 175 -137 314 -209 l109 -57 -44 -32 c-222 -161 -528 -300 -792
-360 -103 -23 -124 -39 -113 -83 6 -23 146 -152 260 -238 76 -57 253 -167 304
-188 17 -7 32 -17 32 -20 0 -4 -44 -32 -97 -63 -254 -146 -460 -228 -763 -304
-194 -48 -199 -62 -75 -180 304 -290 710 -522 1125 -643 129 -37 320 -75 463
-91 l107 -13 0 -31 c0 -17 -11 -143 -25 -281 -14 -137 -25 -264 -25 -282 0
-61 8 -63 249 -63 204 0 219 1 236 20 17 19 17 31 -12 327 -17 170 -30 309
-29 310 0 1 48 7 106 13 266 29 595 119 840 229 268 120 530 295 758 506 72
67 86 97 64 133 -5 6 -78 30 -163 51 -186 47 -297 83 -444 146 -141 60 -395
199 -395 216 0 3 17 13 38 23 169 80 510 337 553 417 13 26 -11 66 -44 74 -12
2 -52 11 -89 20 -219 48 -501 171 -700 305 -60 40 -108 75 -108 79 0 4 39 26
88 50 193 95 452 301 436 346 -13 36 -24 43 -102 60 -369 82 -717 325 -935
652 -69 104 -81 114 -124 97z m116 -273 c191 -244 477 -444 775 -542 l99 -33
-34 -29 c-114 -98 -324 -213 -481 -262 -159 -50 -295 -68 -488 -62 -199 5
-306 24 -465 82 -148 53 -319 150 -420 238 l-39 34 69 22 c97 31 224 85 293
123 223 124 440 316 564 498 31 47 34 49 48 31 8 -10 44 -55 79 -100z m-484
-996 c240 -62 565 -60 803 5 l72 20 63 -48 c199 -155 455 -288 698 -362 57
-18 112 -35 123 -39 16 -6 10 -14 -40 -57 -268 -231 -662 -406 -1034 -460
-131 -19 -414 -22 -540 -5 -410 54 -813 229 -1082 471 l-53 48 45 11 c269 69
624 247 825 415 17 14 37 23 45 21 8 -2 42 -11 75 -20z m-355 -940 c95 -37
268 -81 415 -106 102 -17 172 -22 325 -23 274 0 486 35 732 120 53 19 101 34
107 34 5 0 48 -25 97 -55 228 -143 555 -285 784 -340 41 -10 79 -21 83 -25 10
-9 -141 -137 -264 -222 -272 -190 -548 -317 -861 -397 -262 -68 -334 -76 -673
-76 -319 0 -376 6 -610 60 -348 82 -692 242 -975 456 -157 119 -219 176 -200
183 8 2 71 21 140 41 250 73 494 180 700 309 58 36 112 65 121 66 8 0 44 -11
79 -25z m834 -1237 c6 -30 46 -456 46 -491 l0 -27 -136 0 c-124 0 -135 1 -130
18 2 9 15 131 27 270 l23 252 83 0 c78 0 83 -1 87 -22z"/>
<path d="M2199 3401 c-42 -42 -41 -99 2 -135 80 -68 189 12 144 107 -29 62
-98 75 -146 28z"/>
<path d="M2744 3290 c-32 -13 -64 -59 -64 -92 0 -45 48 -88 97 -88 57 1 93 38
93 95 0 39 -18 65 -55 81 -39 16 -42 16 -71 4z"/>
<path d="M3004 2580 c-59 -24 -72 -104 -25 -151 74 -73 191 6 151 102 -20 48
-76 69 -126 49z"/>
<path d="M1833 2415 c-35 -24 -48 -77 -28 -118 29 -62 98 -75 146 -28 47 47
38 119 -18 149 -30 16 -74 15 -100 -3z"/>
<path d="M2544 2220 c-57 -23 -73 -112 -29 -155 28 -29 88 -34 122 -10 28 20
46 75 34 106 -19 51 -81 79 -127 59z"/>
<path d="M3003 1635 c-38 -16 -53 -42 -53 -88 0 -82 110 -120 164 -56 34 40
34 81 -1 118 -34 36 -68 44 -110 26z"/>
<path d="M1973 1536 c-22 -19 -28 -32 -28 -64 0 -73 65 -115 132 -87 38 16 53
41 53 90 0 78 -95 115 -157 61z"/>
<path d="M3637 1479 c-25 -15 -46 -68 -40 -101 14 -71 112 -99 159 -45 33 39
33 99 -1 132 -26 27 -84 34 -118 14z"/>
<path d="M1407 1409 c-9 -5 -24 -24 -33 -40 -40 -78 47 -165 125 -125 87 46
61 176 -36 176 -21 0 -46 -5 -56 -11z"/>
<path d="M2476 1145 c-57 -53 -15 -165 62 -165 38 0 88 41 95 77 7 35 -15 88
-41 102 -36 19 -87 13 -116 -14z"/>
</g>


    </Svg>
  );
};

export default Icon;
