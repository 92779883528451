import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 500.000000 500.000000" {...props}>
<g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#faf7f3" stroke="none">
<path d="M1813 4966 c-74 -36 -93 -56 -93 -101 0 -31 94 -254 121 -289 10 -13
49 -39 87 -60 39 -20 73 -44 77 -53 4 -10 31 -89 60 -175 30 -86 58 -166 64
-177 21 -38 293 -250 440 -343 l149 -93 68 -153 c37 -84 71 -155 75 -158 4 -2
17 -18 29 -34 l20 -28 -52 0 c-43 0 -77 -10 -183 -56 -196 -87 -220 -126 -141
-234 59 -81 64 -84 213 -108 75 -12 143 -20 151 -17 8 3 -52 -94 -134 -216
-138 -207 -149 -221 -178 -222 -17 0 -94 -6 -171 -12 -135 -12 -149 -11 -395
20 l-255 32 -295 -16 c-248 -13 -314 -14 -416 -3 -66 7 -130 9 -142 6 -11 -4
-59 -47 -105 -96 -110 -116 -251 -342 -251 -405 -1 -157 4 -193 30 -224 32
-38 78 -41 154 -11 28 11 54 20 59 20 12 0 55 -151 121 -430 33 -140 63 -261
65 -267 2 -7 -33 -32 -87 -59 -136 -71 -133 -31 -38 -528 44 -229 84 -425 90
-436 16 -31 43 -40 115 -40 120 0 135 24 136 227 0 76 2 134 4 128 2 -5 16
-76 31 -158 34 -187 42 -197 150 -197 63 0 77 3 101 24 l28 24 5 288 5 288
160 123 c88 68 170 134 182 147 11 14 24 43 28 65 4 23 11 44 16 47 8 5 530
-24 536 -30 1 -1 12 -195 23 -432 12 -236 23 -444 26 -463 3 -18 16 -44 29
-57 22 -22 32 -24 127 -24 78 0 109 4 126 16 20 14 24 14 44 0 17 -12 49 -16
130 -16 172 0 136 -114 304 956 94 597 154 947 173 1019 17 61 75 236 130 390
l99 280 158 5 159 5 90 46 c60 31 106 63 137 97 27 29 63 55 84 62 58 19 129
97 148 161 20 66 20 82 1 147 -50 166 -263 228 -395 115 l-39 -34 -178 52
c-98 28 -194 54 -214 57 l-35 7 15 64 16 65 69 17 c38 10 75 24 82 31 8 7 39
71 69 141 83 193 77 219 -59 276 -74 31 -112 33 -141 10 -7 -5 -20 43 -39 142
-31 164 -32 151 30 219 17 19 35 45 38 57 4 12 -2 88 -14 170 -19 142 -21 149
-51 177 -30 26 -36 28 -90 22 l-58 -7 -15 35 c-20 47 -47 60 -115 53 -123 -13
-181 -48 -181 -109 0 -18 20 -111 44 -205 42 -167 43 -173 23 -173 -11 0 -45
-14 -75 -31 -53 -30 -56 -30 -117 -20 -89 16 -111 14 -140 -11 -21 -20 -28
-41 -45 -138 -29 -162 -24 -156 -92 -114 -55 34 -56 36 -47 64 8 23 3 67 -27
210 -43 205 -56 230 -123 230 -53 0 -163 -28 -183 -47 -16 -14 -18 -14 -24 3
-4 11 -13 34 -20 51 -12 30 -10 40 27 129 21 54 39 110 39 125 0 40 -36 74
-109 103 -89 36 -132 34 -165 -5 -14 -17 -26 -35 -26 -40 0 -26 -20 -2 -43 50
-44 101 -94 114 -214 57z m149 -162 c34 -80 43 -92 81 -112 23 -12 47 -22 52
-22 6 0 24 35 41 78 33 83 34 84 98 57 l34 -14 -39 -93 c-21 -51 -38 -102 -38
-113 0 -11 26 -96 58 -190 l58 -170 69 -57 c38 -32 72 -58 76 -58 24 0 13 111
-29 286 -4 19 2 23 47 34 28 7 52 11 54 9 2 -2 16 -72 33 -155 l30 -152 -24
-47 c-14 -26 -23 -48 -21 -50 9 -8 259 -166 270 -170 8 -3 27 22 50 67 28 55
42 98 53 173 9 55 17 101 19 102 3 4 96 -13 101 -18 2 -2 -7 -58 -19 -124 -19
-102 -29 -132 -69 -203 l-46 -83 67 -149 c64 -142 72 -155 166 -260 118 -130
135 -136 179 -64 15 25 27 51 27 57 0 7 -33 35 -74 64 -77 54 -81 62 -174 283
-1 4 20 16 47 28 l49 20 47 -107 c42 -97 51 -110 96 -141 27 -18 51 -31 53
-29 8 8 56 239 56 268 0 29 -27 171 -34 178 -2 2 -39 14 -82 26 l-78 23 -65
108 c-70 121 -70 114 1 152 l26 14 54 -92 c43 -73 60 -93 83 -100 16 -4 31 -5
33 -3 7 7 -24 155 -84 405 -30 124 -57 232 -60 241 -4 13 7 20 46 29 28 7 52
11 54 9 2 -2 20 -73 41 -156 20 -84 41 -153 46 -153 18 0 21 31 9 116 -6 48
-7 90 -3 94 4 4 29 10 54 13 l45 6 13 -107 c8 -59 16 -112 18 -119 3 -6 -21
-39 -51 -72 -31 -34 -56 -67 -56 -74 0 -7 23 -125 51 -263 27 -137 50 -266 50
-286 0 -30 4 -38 18 -38 29 0 46 22 81 103 18 43 38 76 44 74 6 -2 28 -10 49
-18 20 -7 37 -15 37 -19 0 -3 -22 -54 -48 -114 l-47 -109 -82 -19 c-57 -14
-84 -24 -87 -36 -3 -9 -14 -59 -26 -110 -12 -57 -31 -110 -50 -140 l-29 -47
107 -6 c102 -5 190 -26 522 -124 10 -3 20 6 29 26 7 16 31 42 52 56 32 21 47
25 87 21 60 -6 95 -30 122 -84 25 -50 25 -68 0 -121 -20 -44 -69 -83 -105 -83
-12 0 -46 -26 -80 -61 -36 -36 -88 -75 -130 -97 l-70 -37 -180 -5 -180 -5
-108 -305 c-59 -168 -123 -361 -142 -430 -26 -93 -70 -347 -170 -990 -75 -476
-138 -877 -141 -893 -5 -26 -8 -27 -70 -27 l-64 0 0 43 c0 24 16 280 35 568
19 288 35 530 35 537 0 7 -29 -7 -65 -30 -35 -24 -65 -48 -65 -53 0 -6 -25
-240 -55 -520 -30 -281 -55 -518 -55 -527 0 -15 -10 -18 -65 -18 -55 0 -65 3
-65 18 1 9 -9 227 -22 484 l-22 467 -41 5 c-22 3 -160 13 -307 21 -218 13
-276 19 -310 35 -24 10 -46 16 -49 12 -4 -4 -16 -47 -27 -96 -12 -49 -25 -92
-30 -95 -5 -3 -88 -67 -185 -141 l-177 -135 0 -288 0 -288 -32 3 -32 3 -62
320 c-33 177 -63 327 -65 334 -3 8 -26 -4 -67 -35 l-62 -48 0 -288 0 -288 -33
0 -33 0 -72 383 c-40 210 -73 387 -72 392 0 6 47 34 105 63 58 28 105 58 105
66 0 28 -153 645 -182 737 l-31 96 92 177 c51 98 90 180 88 182 -7 7 -75 -75
-170 -206 l-82 -112 -75 -29 c-41 -16 -75 -29 -76 -29 -1 0 -4 35 -7 77 l-6
77 80 131 c44 73 109 162 147 203 l67 73 110 -12 c91 -10 162 -10 405 4 l295
16 260 -33 260 -32 200 17 c110 9 201 17 202 18 7 3 343 512 343 520 0 4 -14
40 -31 80 l-32 71 -34 -21 c-37 -23 -67 -23 -208 2 -51 9 -64 16 -88 47 -15
19 -24 39 -20 43 5 4 60 29 123 56 l115 49 99 -13 c108 -14 105 -14 137 44 10
19 4 30 -67 110 -73 80 -85 101 -147 243 l-69 155 -161 102 c-89 57 -220 150
-292 209 l-130 106 -63 186 c-35 103 -69 192 -75 199 -7 6 -45 29 -86 51 -80
43 -72 32 -156 229 l-14 33 42 19 c23 11 46 18 51 17 5 -1 27 -42 48 -92z
m1278 -931 c36 -11 70 -26 77 -32 14 -13 28 -115 18 -132 -5 -7 -20 15 -40 59
-48 102 -78 112 -194 63 l-68 -29 33 65 c19 35 34 73 34 83 0 50 13 50 44 -2
28 -49 36 -55 96 -75z"/>
</g>
</Svg>

  );
};

export default Icon;
