import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 500.000000 500.000000" {...props}>
<g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#faf7f3" stroke="none">
<path d="M1305 4935 l-25 -24 0 -476 0 -475 -94 0 c-72 0 -98 -4 -110 -15 -18
-18 -31 -59 -77 -247 l-33 -137 78 -117 79 -118 -6 -60 c-13 -133 -77 -272
-172 -370 -27 -29 -117 -97 -200 -151 -373 -245 -601 -582 -672 -993 -23 -137
-23 -387 1 -512 98 -507 447 -924 921 -1103 166 -63 256 -79 466 -84 231 -6
344 8 508 63 217 72 384 172 550 330 268 255 419 570 451 939 36 422 -114 838
-410 1136 -114 114 -197 180 -324 255 -95 56 -200 157 -243 234 -39 69 -71
162 -82 243 l-9 62 72 110 c40 61 75 120 78 132 8 31 -76 360 -98 384 -15 16
-31 19 -111 19 l-93 0 0 466 c0 334 -3 471 -12 487 -26 50 -77 61 -122 26
l-26 -20 0 -480 0 -479 -75 0 -75 0 0 475 0 476 -25 24 c-15 16 -36 25 -55 25
-19 0 -40 -9 -55 -25z m125 -1260 l0 -125 -98 -49 -98 -49 -46 72 -47 71 26
103 25 102 119 0 119 0 0 -125z m431 20 l26 -104 -46 -69 -46 -70 -98 49 -97
49 0 125 0 125 118 0 118 0 25 -105z m-123 -400 c2 -2 8 -34 13 -71 35 -251
169 -446 407 -592 326 -201 529 -471 617 -822 37 -144 45 -363 20 -511 -46
-277 -169 -515 -370 -715 -148 -147 -321 -253 -520 -319 -264 -87 -574 -79
-845 22 -540 200 -885 740 -839 1313 25 314 143 577 364 809 78 82 157 144
280 221 177 110 261 199 336 355 41 85 79 216 79 272 0 32 3 34 117 92 l118
59 110 -55 c60 -31 111 -57 113 -58z"/>
<path d="M1467 2568 c-29 -23 -37 -66 -37 -196 l0 -127 -82 82 c-95 93 -118
107 -159 98 -37 -8 -59 -40 -59 -83 0 -31 16 -51 150 -187 l150 -152 -2 -176
-3 -176 -187 107 -187 107 -36 140 c-21 77 -44 150 -52 163 -20 33 -60 46 -95
31 -50 -21 -60 -64 -33 -153 8 -27 13 -51 11 -54 -3 -2 -41 16 -85 42 -96 55
-139 60 -170 20 -26 -33 -27 -78 -3 -102 11 -11 53 -38 95 -62 43 -24 77 -47
77 -51 0 -3 -27 -12 -61 -19 -81 -17 -109 -40 -109 -91 0 -47 27 -79 68 -79
15 0 92 16 172 36 l145 37 187 -109 c111 -65 182 -112 175 -116 -7 -4 -77 -45
-156 -91 l-145 -84 -105 29 c-308 83 -310 83 -339 67 -50 -28 -55 -103 -9
-133 12 -9 67 -27 122 -42 55 -14 106 -29 113 -33 7 -4 -31 -32 -100 -71 -61
-35 -120 -72 -130 -82 -26 -26 -23 -79 5 -106 39 -37 78 -29 202 43 61 36 114
65 117 65 3 0 -8 -46 -23 -102 -34 -125 -36 -171 -9 -198 21 -21 69 -26 99
-10 24 13 32 34 91 250 27 102 53 186 58 188 4 1 72 40 152 87 l145 84 3 -217
2 -216 -104 -106 c-58 -58 -110 -117 -115 -133 -13 -33 -1 -69 31 -91 39 -28
66 -20 129 41 l59 56 0 -97 c0 -101 13 -147 45 -160 32 -12 75 -6 95 14 18 18
20 33 20 132 l0 112 48 -46 c76 -73 109 -84 156 -47 20 16 26 29 26 61 0 37
-7 47 -115 157 l-115 118 0 211 c0 117 3 212 6 212 8 0 287 -161 302 -175 7
-6 37 -101 67 -211 58 -215 69 -234 129 -234 41 0 76 35 76 76 -1 16 -14 76
-30 134 -15 58 -27 106 -25 108 2 2 55 -26 118 -62 131 -76 172 -84 206 -40
48 61 19 100 -136 188 -57 32 -103 60 -103 62 0 2 26 11 58 19 170 47 192 56
208 80 26 40 11 92 -31 112 -33 16 -39 15 -239 -39 l-205 -55 -151 88 c-83 48
-153 89 -156 91 -2 2 81 53 184 113 l187 108 145 -37 c80 -20 157 -37 171 -36
34 0 69 39 69 76 0 49 -23 71 -100 90 -38 10 -70 22 -70 26 0 3 34 25 75 48
114 63 142 114 90 165 -36 37 -75 32 -167 -21 -44 -25 -82 -43 -85 -41 -2 3 3
29 11 58 21 70 20 101 -4 127 -48 52 -123 29 -141 -43 -6 -22 -25 -92 -42
-155 l-32 -115 -185 -107 c-102 -59 -188 -108 -192 -108 -5 0 -8 80 -8 177 l0
177 155 156 c130 131 155 161 155 186 -1 51 -50 91 -98 79 -14 -3 -68 -47
-119 -98 l-93 -91 0 152 c0 143 -1 152 -22 172 -25 23 -76 27 -101 8z"/>
<path d="M3849 4947 c-34 -26 -39 -55 -39 -211 l0 -156 -114 0 c-63 0 -126 -4
-141 -10 -46 -18 -55 -59 -55 -265 0 -199 10 -252 50 -262 28 -7 25 -28 -14
-110 -41 -84 -88 -135 -177 -188 -322 -194 -501 -474 -539 -845 -37 -364 127
-730 425 -953 129 -96 291 -169 440 -198 102 -20 342 -18 433 4 225 54 391
146 547 304 186 189 290 429 302 699 7 150 -7 255 -53 394 -80 242 -241 444
-466 581 -54 34 -114 80 -133 102 -63 74 -118 207 -85 207 5 0 18 10 30 22 19
20 20 34 20 234 0 138 -4 222 -11 238 -17 36 -57 46 -185 46 l-114 0 0 169 0
170 -26 20 c-30 23 -71 27 -95 8z m271 -642 l0 -115 -225 0 -225 0 0 115 0
115 225 0 225 0 0 -115z m-60 -327 c30 -142 143 -292 278 -372 266 -156 426
-397 464 -698 34 -278 -77 -572 -291 -771 -80 -74 -133 -109 -233 -157 -214
-101 -446 -114 -677 -37 -235 79 -415 237 -526 462 -77 157 -90 217 -90 405 0
170 15 244 74 375 61 133 193 293 303 366 29 19 86 57 126 83 40 27 90 69 112
95 50 58 105 161 120 226 7 28 13 56 16 63 2 9 43 12 158 12 l154 0 12 -52z"/>
<path d="M3860 3417 c-45 -23 -50 -48 -50 -264 l0 -202 -182 105 c-101 57
-193 104 -205 104 -27 0 -71 -38 -74 -65 -8 -62 9 -76 293 -236 43 -24 78 -47
78 -50 0 -4 -73 -48 -162 -99 -195 -111 -208 -121 -208 -171 0 -44 35 -79 79
-79 15 0 100 43 199 100 94 55 174 100 177 100 3 0 5 -90 5 -201 0 -202 4
-229 39 -256 24 -19 65 -15 94 8 l27 20 2 218 3 217 175 -102 c193 -113 223
-122 265 -79 32 31 33 78 3 108 -13 12 -98 65 -190 117 -144 81 -166 97 -152
107 10 7 88 53 176 104 161 92 188 115 188 157 0 29 -51 82 -80 82 -11 0 -103
-47 -203 -104 l-182 -105 -5 222 c-3 122 -7 223 -8 224 -5 6 -62 33 -69 33 -4
0 -19 -6 -33 -13z"/>
</g>

    </Svg>
  );
};

export default Icon;
