import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 500.000000 500.000000" {...props}>
<g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#faf7f3" stroke="none">
<path d="M2410 4915 l0 -85 -80 0 -80 0 0 -80 0 -80 80 0 80 0 0 -85 0 -85 85
0 85 0 0 85 0 85 85 0 85 0 0 80 0 80 -85 0 -85 0 0 85 0 85 -85 0 -85 0 0
-85z m170 -165 l0 -80 -85 0 -85 0 0 80 0 80 85 0 85 0 0 -80z"/>
<path d="M1000 4500 l0 -80 80 0 80 0 0 -85 0 -85 -80 0 -79 0 -3 83 -3 82
-82 3 -83 3 0 -86 0 -85 85 0 85 0 0 -85 0 -85 80 0 80 0 0 85 0 85 85 0 85 0
0 85 0 85 -85 0 -85 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
<path d="M3750 4500 l0 -80 80 0 80 0 0 -85 0 -85 -80 0 -79 0 -3 83 -3 82
-82 3 -83 3 0 -86 0 -85 85 0 85 0 0 -85 0 -85 80 0 80 0 0 85 0 85 85 0 85 0
0 85 0 85 -85 0 -85 0 0 80 0 80 -80 0 -80 0 0 -80z"/>
<path d="M1848 4064 c-185 -44 -354 -210 -412 -402 -52 -176 -12 -380 101
-519 24 -29 43 -55 43 -58 0 -3 -243 -5 -540 -5 l-540 0 0 -500 0 -500 80 0
80 0 0 -1040 0 -1040 1835 0 1835 0 0 1040 0 1039 83 3 82 3 3 498 2 497 -545
0 c-300 0 -545 3 -545 6 0 3 17 25 38 49 71 81 132 247 132 359 0 82 -27 185
-70 272 -70 143 -219 263 -372 299 -132 31 -279 14 -405 -47 -71 -35 -178
-127 -214 -184 l-21 -34 -37 48 c-122 155 -283 233 -476 231 -44 0 -106 -7
-137 -15z m288 -175 c100 -38 184 -110 229 -198 40 -79 45 -119 45 -368 l0
-243 -207 0 c-115 0 -234 4 -267 10 -335 55 -472 463 -236 700 117 116 285
154 436 99z m1000 0 c169 -63 274 -212 274 -389 0 -205 -149 -376 -356 -410
-33 -6 -152 -10 -266 -10 l-208 0 0 233 c0 254 9 314 57 396 57 98 178 181
290 201 63 11 145 2 209 -21z m-1138 -1306 l2 -333 -670 0 -670 0 0 335 0 335
668 -2 667 -3 3 -332z m832 -1038 l0 -1375 -335 0 -335 0 0 1375 0 1375 335 0
335 0 0 -1375z m1500 1040 l0 -335 -665 0 -665 0 0 335 0 335 665 0 665 0 0
-335z m-2330 -1460 l0 -955 -585 0 -585 0 0 955 0 955 585 0 585 0 0 -955z
m2160 0 l0 -955 -580 0 -580 0 0 955 0 955 580 0 580 0 0 -955z"/>
<path d="M67 3672 l-57 -57 57 -58 58 -57 -58 -58 -57 -58 60 -59 60 -60 57
58 58 57 -60 60 -59 60 59 60 59 60 -54 55 c-30 30 -57 55 -60 55 -3 0 -31
-26 -63 -58z"/>
<path d="M300 3675 l-54 -55 59 -60 59 -60 -59 -60 -60 -60 58 -57 57 -58 60
60 60 59 -57 58 -57 58 57 58 57 58 -58 57 c-32 31 -60 57 -63 57 -2 0 -29
-25 -59 -55z"/>
<path d="M4567 3672 l-57 -57 57 -58 58 -57 -58 -58 -57 -58 60 -59 60 -60 57
58 58 57 -60 60 -59 60 59 60 59 60 -54 55 c-30 30 -57 55 -60 55 -3 0 -31
-26 -63 -58z"/>
<path d="M4800 3675 l-54 -55 59 -60 59 -60 -59 -60 -60 -60 58 -57 57 -58 60
60 60 59 -57 58 -57 58 57 58 57 58 -58 57 c-32 31 -60 57 -63 57 -2 0 -29
-25 -59 -55z"/>
</g>
</Svg>

  );
};

export default Icon;
