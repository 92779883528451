import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 500.000000 500.000000" {...props}>
<g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#faf7f3" stroke="none">
<path d="M987 4465 c-504 -85 -889 -500 -947 -1023 -19 -167 23 -433 85 -538
51 -87 135 -135 246 -142 67 -4 84 -1 132 21 61 28 126 89 151 142 9 19 21 58
27 88 10 46 8 66 -10 137 -44 169 -37 284 24 418 100 216 344 343 584 302 158
-27 310 -136 383 -277 20 -37 88 -219 151 -403 64 -184 118 -342 122 -351 5
-14 -6 -13 -77 9 -58 17 -109 25 -170 26 -81 1 -89 -1 -130 -29 -81 -57 -83
-64 -86 -437 -2 -213 1 -342 8 -368 11 -41 58 -103 91 -121 41 -22 110 -27
187 -13 42 7 77 12 79 11 1 -1 -13 -47 -32 -102 -40 -118 -64 -229 -72 -335
-5 -70 -4 -79 16 -99 11 -11 30 -21 41 -21 11 0 20 -2 20 -4 0 -2 -36 -108
-81 -237 -75 -218 -80 -239 -76 -299 14 -226 251 -353 451 -242 90 50 109 88
233 444 61 178 114 316 117 308 3 -8 55 -156 115 -329 115 -330 130 -360 207
-413 187 -128 447 -4 471 225 5 50 -2 76 -76 295 -44 132 -81 243 -81 246 0 3
9 6 20 6 33 0 60 35 60 79 0 72 -39 267 -74 372 -19 57 -33 105 -32 107 2 1
25 -2 52 -8 67 -15 155 -14 196 3 51 21 94 78 108 139 8 36 11 154 8 368 -3
305 -4 316 -26 357 -12 24 -41 55 -64 70 -38 25 -49 28 -127 27 -59 -1 -112
-9 -168 -26 -46 -14 -83 -24 -83 -22 0 22 260 744 283 783 97 172 309 283 508
267 355 -28 581 -363 480 -711 -50 -171 11 -311 167 -383 47 -21 65 -24 131
-20 211 13 314 176 328 519 7 164 -6 265 -52 404 -61 187 -150 332 -285 466
-168 165 -383 275 -620 314 -142 24 -336 16 -473 -19 -310 -80 -588 -300 -734
-581 -23 -44 -85 -206 -138 -360 -54 -154 -100 -283 -104 -287 -3 -4 -44 104
-90 240 -109 317 -141 395 -211 502 -176 268 -463 455 -774 505 -110 18 -282
18 -389 0z m412 -139 c40 -9 79 -20 87 -24 10 -5 -12 -44 -86 -150 l-99 -143
-123 -3 c-84 -1 -143 -8 -185 -20 l-62 -18 -141 112 c-77 61 -140 114 -140
116 0 2 35 21 78 42 89 45 181 76 267 91 33 6 71 13 85 15 48 9 246 -3 319
-18z m2526 0 c96 -21 172 -48 256 -92 56 -30 68 -40 57 -49 -7 -7 -71 -58
-142 -115 l-128 -103 -62 19 c-41 12 -101 18 -185 20 l-124 3 -89 128 c-123
177 -118 159 -54 178 126 37 331 42 471 11z m-2252 -102 c110 -57 246 -175
326 -283 54 -72 135 -224 123 -229 -5 -1 -92 4 -196 12 l-188 14 -61 62 c-66
67 -116 104 -187 140 -25 12 -47 24 -48 26 -6 5 181 274 190 274 5 0 23 -7 41
-16z m1695 -120 c52 -75 92 -137 90 -139 -1 -1 -26 -14 -54 -28 -70 -35 -141
-89 -195 -149 l-46 -50 -193 -15 c-106 -7 -194 -12 -197 -9 -6 6 43 101 94
181 57 87 148 183 238 250 70 52 141 94 160 94 6 0 52 -61 103 -135z m-2700
-96 c70 -56 128 -102 130 -104 2 -1 -37 -42 -86 -90 -100 -100 -155 -195 -186
-321 -11 -44 -22 -70 -29 -68 -7 2 -79 16 -162 30 -82 15 -152 29 -153 30 -2
2 6 43 18 92 25 101 89 243 150 332 45 67 166 201 182 201 5 0 66 -46 136
-102z m3775 31 c125 -133 209 -284 253 -457 13 -52 23 -96 22 -97 -3 -2 -291
-55 -316 -58 -9 -1 -20 23 -32 68 -10 39 -26 88 -35 110 -31 72 -105 173 -172
234 l-67 60 25 19 c13 11 71 58 129 105 58 47 109 86 115 86 5 1 40 -31 78
-70z m-2444 -455 c74 -4 146 -10 159 -13 22 -6 36 -39 133 -321 59 -173 107
-316 105 -317 -2 -2 -83 2 -181 9 l-178 13 -25 75 c-13 41 -62 183 -108 315
-46 133 -84 245 -84 249 0 5 10 6 23 3 12 -3 83 -9 156 -13z m1078 -1 c-3 -10
-53 -155 -110 -323 l-105 -305 -98 -7 c-55 -3 -127 -9 -161 -12 l-62 -5 -11
33 c-9 29 -1 59 86 312 53 153 100 283 105 288 5 5 82 15 171 21 90 7 169 13
177 14 8 1 11 -5 8 -16z m-2723 -268 c77 -14 143 -25 148 -25 4 0 8 -12 8 -28
0 -15 9 -60 20 -100 26 -92 25 -137 -3 -187 -25 -45 -92 -85 -142 -85 -40 0
-114 38 -138 71 -27 38 -66 198 -74 302 l-6 88 24 -5 c13 -3 87 -17 163 -31z
m4372 -46 c-11 -138 -42 -260 -79 -313 -23 -32 -88 -65 -129 -66 -49 0 -109
31 -139 73 -34 46 -38 102 -15 183 8 27 18 70 21 95 l7 47 96 17 c54 9 124 23
157 30 95 21 89 26 81 -66z m-2451 -464 c168 -10 171 -11 189 -56 9 -22 16
-41 16 -43 0 -2 -27 -6 -61 -9 -43 -4 -75 -14 -107 -34 l-47 -29 -81 58 c-63
46 -83 66 -92 94 l-11 36 47 -6 c26 -3 92 -8 147 -11z m535 5 c0 -20 -29 -80
-38 -80 -6 0 -37 -20 -70 -44 l-61 -45 -25 76 c-14 42 -24 79 -21 81 3 3 44 8
92 12 49 4 96 8 106 9 9 0 17 -4 17 -9z m-990 -91 c95 -25 218 -84 299 -144
65 -48 74 -63 27 -45 -65 24 -118 -1 -118 -55 0 -33 16 -52 60 -66 58 -18 58
-21 4 -41 -60 -22 -79 -56 -56 -101 19 -35 49 -41 103 -22 24 8 46 14 48 12 7
-7 -101 -84 -167 -120 -86 -47 -235 -96 -313 -104 -102 -9 -97 -28 -97 356 0
251 3 331 13 339 29 24 78 22 197 -9z m1458 3 c9 -12 12 -95 12 -337 0 -309
-1 -323 -20 -340 -55 -50 -336 40 -498 160 -35 25 -62 48 -60 51 3 2 24 -3 47
-11 69 -24 111 -2 111 58 0 30 -24 53 -76 71 l-39 14 30 11 c66 24 80 36 83
70 5 49 -22 75 -73 68 -22 -3 -51 -9 -65 -13 -24 -7 -24 -6 5 18 117 97 284
173 433 198 57 9 93 3 110 -18z m-750 -178 c44 -29 62 -73 62 -152 0 -43 -6
-84 -14 -100 -53 -101 -188 -110 -246 -17 -30 49 -28 179 3 226 44 66 131 85
195 43z m-226 -427 l27 -22 -34 -70 c-65 -134 -102 -280 -120 -467 l-7 -68
-149 0 c-130 0 -149 2 -149 16 0 42 44 226 71 295 48 126 95 188 175 236 38
23 85 55 104 72 42 36 47 37 82 8z m374 -8 c22 -17 69 -49 106 -71 78 -46 120
-102 168 -221 29 -72 61 -194 75 -284 l7 -43 -150 0 c-169 0 -162 -3 -162 80
0 118 -54 329 -117 455 l-35 69 28 23 c16 13 32 23 35 23 3 0 24 -14 45 -31z
m-182 -170 c61 -133 96 -303 96 -466 0 -111 3 -113 195 -113 144 0 155 -1 165
-20 13 -24 26 -24 -185 -9 -88 6 -164 15 -169 20 -6 5 -53 134 -106 288 l-96
279 25 51 c14 28 28 51 32 51 3 0 23 -37 43 -81z m-152 -491 l-37 -103 -145
-12 c-215 -17 -219 -17 -213 7 5 19 14 20 163 20 202 0 195 -6 202 174 3 68 9
130 14 137 5 8 16 -11 30 -54 l23 -67 -37 -102z m-156 -451 c-85 -248 -95
-268 -138 -299 -26 -18 -46 -23 -95 -23 -69 0 -101 17 -140 75 -37 53 -31 111
28 282 44 126 55 148 73 149 28 1 329 26 338 28 4 0 -26 -95 -66 -212z m673
193 c80 -6 146 -12 147 -13 1 -1 24 -69 53 -150 59 -170 64 -225 29 -277 -34
-50 -71 -72 -126 -78 -60 -5 -112 17 -147 62 -13 17 -56 125 -95 241 -40 116
-75 215 -77 222 -3 8 7 10 34 7 21 -2 103 -9 182 -14z"/>
</g>
    </Svg>
  );
};

export default Icon;
