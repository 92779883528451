import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'XPAST-FTM LP',
    lpAddresses: {
      250: '0x9665067DceF6a88d2dCf042ee25A2d98a2DDF8D6',
    },
    tokenSymbol: 'FTM',
    tokenAddresses: {
      250: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },

  {
    pid: 1,
    risk: 5,
    lpSymbol: 'XPAST-USDC LP',
    lpAddresses: {
      250: '0x73B019D2B6fD28D85eeAD4E85909d69Cc0472D5F',
    },
    tokenSymbol: 'XPAST',
    tokenAddresses: {
      250: '0xD3111Fb8BDf936B11fFC9eba3b597BeA21e72724',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },

  {
    pid: 2,
    risk: 5,
    lpSymbol: 'FTM-USDC LP',
    lpAddresses: {
      250: '0x2b4c76d0dc16be1c31d4c1dc53bf9b45987fc75c',
    },
    tokenSymbol: 'FTM',
    tokenAddresses: {
      250: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  
  {
    pid: 5,
    risk: 5,
    lpSymbol: 'FTM-FUSDT LP',
    lpAddresses: {
      250: '0x5965e53aa80a0bcf1cd6dbdd72e6a9b2aa047410',
    },
    tokenSymbol: 'FTM',
    tokenAddresses: {
      250: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83',
    },
    quoteTokenSymbol: QuoteToken.fUSDT,
    quoteTokenAdresses: contracts.fusdt,
  },


  {
    pid: 6,
    risk: 5,
    lpSymbol: 'USDC-FUSDT LP',
    lpAddresses: {
      250: '0xfdef392adc84607135c24ca45de5452d77aa10de',
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      250: '0x04068da6c83afcfa0e13ba15a6696662335d5b75',
    },
    quoteTokenSymbol: QuoteToken.fUSDT,
    quoteTokenAdresses: contracts.fusdt,
  },

  {
    pid: 7,
    risk: 5,
    lpSymbol: 'FTM-WBTC LP',
    lpAddresses: {
      250: '0xfdb9ab8b9513ad9e419cf19530fee49d412c3ee3',
    },
    tokenSymbol: 'WBTC',
    tokenAddresses: {
      250: '0x321162cd933e2be498cd2267a90534a804051b11',
    },
    quoteTokenSymbol: QuoteToken.WFTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 8,
    risk: 5,
    lpSymbol: 'FTM-WETH LP',
    lpAddresses: {
      250: '0xf0702249f4d3a25cd3ded7859a165693685ab577',
    },
    tokenSymbol: 'WETH',
    tokenAddresses: {
      250: '0x74b23882a30290451a17c44f4f05243b6b58c76d',
    },
    quoteTokenSymbol: QuoteToken.WFTM,
    quoteTokenAdresses: contracts.wftm,
  },

  {
    pid: 9,
    risk: 5,
    lpSymbol: 'WBTC-WETH LP',
    lpAddresses: {
      250: '0xec454eda10accdd66209c57af8c12924556f3abd',
    },
    tokenSymbol: 'WBTC',
    tokenAddresses: {
      250: '0x321162cd933e2be498cd2267a90534a804051b11',
    },
    quoteTokenSymbol: QuoteToken.WETH,
    quoteTokenAdresses: contracts.weth,
  },

  {
    pid: 3,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'XPAST',
    lpAddresses: {
      250: '0x73B019D2B6fD28D85eeAD4E85909d69Cc0472D5F'
    },
    tokenSymbol: 'XPAST',
    tokenAddresses: {
      250: '0xD3111Fb8BDf936B11fFC9eba3b597BeA21e72724'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },

  {
    pid: 4,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'WFTM',
    lpAddresses: {
      250: '0x2b4c76d0dc16be1c31d4c1dc53bf9b45987fc75c'
    },
    tokenSymbol: 'WFTM',
    tokenAddresses: {
      250: '0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 10,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'WMEMO',
    lpAddresses: {
      250: '0xeaa8c3140c7a307bf2d86b4460a54011e3ec77f8'
    },
    tokenSymbol: 'WMEMO',
    tokenAddresses: {
      250: '0xddc0385169797937066bbd8ef409b5b3c0dfeb52'
    },
    quoteTokenSymbol: QuoteToken.WFTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 20,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'WSHEC',
    lpAddresses: {
      250: '0x0bfe6f893a6bc443b575ddf361a30f39aa03e59c'
    },
    tokenSymbol: 'WSHEC',
    tokenAddresses: {
      250: '0x94ccf60f700146bea8ef7832820800e2dfa92eda'
    },
    quoteTokenSymbol: QuoteToken.WFTM,
    quoteTokenAdresses: contracts.wftm,
  },


  {
    pid: 11,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'WBTC',
    lpAddresses: {
      250: '0xfdb9ab8b9513ad9e419cf19530fee49d412c3ee3'
    },
    tokenSymbol: 'WBTC',
    tokenAddresses: {
      250: '0x321162cd933e2be498cd2267a90534a804051b11'
    },
    quoteTokenSymbol: QuoteToken.WFTM,
    quoteTokenAdresses: contracts.wftm,
  },

  {
    pid: 12,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'WETH',
    lpAddresses: {
      250: '0xf0702249f4d3a25cd3ded7859a165693685ab577'
    },
    tokenSymbol: 'WETH',
    tokenAddresses: {
      250: '0x74b23882a30290451a17c44f4f05243b6b58c76d'
    },
    quoteTokenSymbol: QuoteToken.WFTM,
    quoteTokenAdresses: contracts.wftm,
  },

  {
    pid: 13,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'USDT',
    lpAddresses: {
      250: '0xfdef392adc84607135c24ca45de5452d77aa10de'
    },
    tokenSymbol: 'USDT',
    tokenAddresses: {
      250: '0x049d68029688eabf473097a2fc38ef61633a3c7a'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },

  {
    pid: 14,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'USDC',
    lpAddresses: {
      250: '0xfdef392adc84607135c24ca45de5452d77aa10de'
    },
    tokenSymbol: 'USDC',
    tokenAddresses: {
      250: '0x04068da6c83afcfa0e13ba15a6696662335d5b75'
    },
    quoteTokenSymbol: QuoteToken.fUSDT,
    quoteTokenAdresses: contracts.fusdt,
  },

  {
    pid: 15,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'DAI',
    lpAddresses: {
      250: '0xe120ffbda0d14f3bb6d6053e90e63c572a66a428'
    },
    tokenSymbol: 'DAI',
    tokenAddresses: {
      250: '0x8d11ec38a3eb5e956b052f67da8bdc9bef8abf3e'
    },
    quoteTokenSymbol: QuoteToken.WFTM,
    quoteTokenAdresses: contracts.wftm,
  },

  {
    pid: 16,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'SPELL',
    lpAddresses: {
      250: '0x78f82c16992932efdd18d93f889141ccf326dbc2'
    },
    tokenSymbol: 'SPELL',
    tokenAddresses: {
      250: '0x468003b688943977e6130f4f68f23aad939a1040'
    },
    quoteTokenSymbol: QuoteToken.WFTM,
    quoteTokenAdresses: contracts.wftm,
  },

  {
    pid: 17,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'MIM',
    lpAddresses: {
      250: '0x6f86e65b255c9111109d2d2325ca2dfc82456efc'
    },
    tokenSymbol: 'MIM',
    tokenAddresses: {
      250: '0x82f0b8b456c1a451378467398982d4834b6829c1'
    },
    quoteTokenSymbol: QuoteToken.WFTM,
    quoteTokenAdresses: contracts.wftm,
  },

  {
    pid: 18,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'ICE',
    lpAddresses: {
      250: '0x623ee4a7f290d11c11315994db70fb148b13021d'
    },
    tokenSymbol: 'ICE',
    tokenAddresses: {
      250: '0xf16e81dce15b08f326220742020379b855b87df9'
    },
    quoteTokenSymbol: QuoteToken.WFTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 19,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'BAKER',
    lpAddresses: {
      250: '0x1Eec8ebC5Fb485d2B9BbC337E0B4F182Ee7a4753'
    },
    tokenSymbol: 'BAKER',
    tokenAddresses: {
      250: '0xfe27133F2E8C8539363883d914Bccb4b21eBd28a'
    },
    quoteTokenSymbol: QuoteToken.WFTM,
    quoteTokenAdresses: contracts.wftm,
  },
  {
    pid: 21,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'BOUJE',
    lpAddresses: {
      250: '0xacf6728c977E349440827375C73Ee23bCE4Db291'
    },
    tokenSymbol: 'BOUJE',
    tokenAddresses: {
      250: '0x37f70aa9fefc8971117bd53a1ddc2372aa7eec41'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 22,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'FOLIVE',
    lpAddresses: {
      250: '0x2438d4f480860aea036d5711fa1b7dcd621dc5d7'
    },
    tokenSymbol: 'FOLIVE',
    tokenAddresses: {
      250: '0xA9937092c4E2B0277C16802Cc8778D252854688A'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 23,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'CGS',
    lpAddresses: {
      250: '0x369F274af8bA9D1523586e0A863Df7d267eDe716'
    },
    tokenSymbol: 'CGS',
    tokenAddresses: {
      250: '0x5a2e451Fb1b46FDE7718315661013ae1aE68e28C'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
  {
    pid: 24,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'VIVE',
    lpAddresses: {
      250: '0x2D2Ce3AD0Bf68624c8C80F26e0863214af284218'
    },
    tokenSymbol: 'VIVE',
    tokenAddresses: {
      250: '0xE509Db88B3c26D45f1fFf45b48E7c36A8399B45A'
    },
    quoteTokenSymbol: QuoteToken.USDC,
    quoteTokenAdresses: contracts.usdc,
  },
]

export default farms


