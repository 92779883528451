import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 500.000000 500.000000" {...props}>
      <g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#faf7f3" stroke="none">
<path d="M2269 3512 l-200 -407 -442 -64 c-243 -35 -446 -66 -451 -69 -5 -3
138 -149 317 -326 l325 -321 -77 -445 c-43 -245 -78 -446 -77 -447 1 -1 181
93 401 208 220 115 403 209 407 209 12 0 114 -52 476 -242 178 -94 326 -168
329 -164 3 3 -28 204 -70 446 l-76 442 227 221 c125 122 271 264 325 316 53
52 94 98 90 101 -5 4 -207 36 -450 71 l-442 64 -201 407 c-111 225 -204 408
-206 408 -2 0 -95 -183 -205 -408z m374 -178 l164 -330 364 -53 c200 -29 366
-55 369 -58 2 -2 -34 -41 -80 -86 -47 -45 -167 -161 -266 -259 l-182 -178 62
-362 c35 -200 60 -364 56 -366 -3 -1 -151 74 -328 168 -178 93 -325 170 -328
170 -2 0 -151 -77 -330 -171 -179 -94 -327 -170 -328 -168 -2 2 24 166 59 364
l63 360 -270 265 -269 265 372 55 373 55 163 334 c90 184 165 332 168 330 2
-3 78 -153 168 -335z"/>
</g>

         </Svg>
  );
};

export default Icon;
