import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 500.000000 500.000000" {...props}>
<g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#faf7f3" stroke="none">
<path d="M2100 4991 c-77 -24 -148 -90 -168 -160 -8 -26 -12 -160 -12 -424 l0
-385 28 -53 c36 -68 95 -114 167 -129 l55 -11 -2 -682 -3 -681 -542 -286
c-298 -157 -572 -306 -610 -332 -189 -129 -339 -350 -399 -586 -23 -89 -25
-111 -21 -267 4 -184 13 -228 72 -373 198 -487 767 -740 1277 -568 97 32 1739
823 1816 874 162 107 295 280 359 467 53 157 53 151 53 1335 0 1193 -4 1110
51 1110 50 0 136 67 172 135 22 39 22 49 22 440 l0 400 -29 53 c-16 28 -44 63
-62 77 -75 57 -27 55 -1158 54 -572 0 -1052 -4 -1066 -8z m2125 -186 l25 -24
0 -366 0 -366 -25 -24 c-20 -21 -34 -25 -79 -25 l-56 0 -2 333 -3 332 -82 3
-83 3 0 -336 0 -335 -85 0 -85 0 0 335 0 335 -80 0 -80 0 0 -335 0 -335 -85 0
-85 0 0 335 0 335 -85 0 -85 0 0 -335 0 -335 -85 0 -85 0 0 335 0 335 -80 0
-80 0 0 -335 0 -335 -85 0 -85 0 0 335 0 336 -82 -3 -83 -3 -3 -332 -2 -333
-80 0 -80 0 0 165 0 165 -85 0 -85 0 0 -165 0 -165 -58 0 c-50 0 -62 4 -83 26
l-24 26 0 365 0 365 28 24 28 24 1030 0 1030 0 24 -25z m-225 -1168 l0 -192
-165 -165 -165 -165 -233 233 c-225 224 -234 232 -272 232 -39 0 -46 -6 -267
-232 l-228 -233 -170 170 -170 170 0 187 0 188 835 0 835 0 0 -193z m-598
-487 c285 -293 245 -286 450 -83 l148 147 0 -214 0 -215 -167 -167 -168 -168
-232 232 c-216 216 -235 232 -268 232 -33 0 -52 -16 -268 -232 l-232 -232
-168 168 -167 168 0 214 0 214 149 -147 c139 -138 151 -147 187 -147 36 0 48
10 269 230 126 126 233 230 236 230 4 0 107 -103 231 -230z m-2 -665 c230
-230 255 -249 302 -225 13 7 85 73 161 148 l137 137 0 -195 0 -195 -75 -18
c-226 -54 -443 -197 -587 -387 -64 -83 -151 -262 -179 -365 -30 -114 -38 -338
-14 -455 9 -47 17 -92 18 -99 2 -18 -882 -446 -894 -434 -4 5 -192 343 -418
752 l-410 745 57 31 c31 17 219 116 417 220 198 103 372 199 388 213 25 24 27
31 27 104 0 43 3 78 7 78 4 0 73 -65 153 -145 153 -152 165 -159 218 -131 15
8 124 112 242 230 118 119 217 216 220 216 3 0 106 -101 230 -225z m600 -692
c0 -123 -5 -211 -14 -254 -22 -102 -73 -214 -137 -298 -92 -122 -170 -178
-391 -284 l-137 -65 -13 56 c-20 90 -14 292 11 387 41 157 145 333 254 431
100 90 186 141 312 185 125 45 115 59 115 -158z m-2294 -725 c225 -407 410
-744 412 -748 4 -11 -198 -102 -230 -104 -28 -1 -36 14 -417 734 l-389 735 31
27 c32 28 154 97 173 98 6 0 195 -334 420 -742z m-380 -198 c200 -381 364
-694 364 -696 0 -2 -47 -4 -103 -4 -212 0 -415 82 -574 233 -107 101 -194 248
-235 398 -19 69 -23 109 -23 219 1 153 20 240 80 365 36 74 114 190 122 181 3
-3 169 -316 369 -696z"/>
<path d="M2250 4585 l0 -85 85 0 85 0 0 85 0 85 -85 0 -85 0 0 -85z"/>
</g>
</Svg>

  );
};

export default Icon;
