import React from "react";
import Svg from "../../../components/Svg/Svg";
import { SvgProps } from "../../../components/Svg/types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 500.000000 500.000000" {...props}>
<g transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
fill="#faf7f3" stroke="none">
<path d="M1248 3762 c-1425 -1424 -1282 -1253 -1151 -1380 52 -51 99 -85 143
-106 59 -28 74 -31 165 -31 183 0 113 -58 1006 832 426 425 778 770 782 767 4
-4 7 -90 7 -191 0 -142 3 -192 15 -220 56 -134 205 -192 325 -127 44 24 98 84
113 126 5 15 11 16 37 6 18 -6 52 -8 76 -4 112 15 195 99 211 211 3 25 11 45
17 45 6 0 85 -74 176 -165 146 -146 169 -165 197 -165 44 0 73 30 73 76 0 34
-13 49 -157 196 -176 178 -222 211 -294 212 -38 1 -54 -5 -85 -30 -56 -45 -74
-74 -81 -132 -4 -40 -12 -58 -31 -72 -52 -41 -112 -13 -127 60 -10 50 -32 70
-76 70 -29 0 -42 -6 -58 -26 -18 -23 -21 -41 -21 -120 0 -102 -10 -135 -46
-155 -35 -18 -75 -2 -96 41 -15 30 -18 64 -18 218 0 204 -4 221 -65 268 -42
32 -107 41 -161 24 -32 -10 -180 -154 -804 -780 -553 -556 -777 -775 -810
-791 -57 -29 -150 -31 -200 -4 -50 26 -100 65 -100 79 0 6 518 529 1150 1161
l1150 1150 1150 -1150 c632 -632 1150 -1155 1150 -1161 0 -14 -50 -53 -100
-79 -51 -27 -143 -25 -200 4 -31 16 -178 156 -460 437 -375 374 -419 414 -448
414 -44 0 -72 -30 -72 -77 0 -36 16 -54 423 -460 391 -391 427 -424 492 -455
65 -31 78 -33 170 -33 91 0 106 3 166 31 44 21 91 56 143 105 69 68 76 78 76
114 0 38 -8 47 -310 350 l-310 310 0 690 0 690 40 23 c90 51 135 165 105 268
-19 67 -82 134 -143 152 -60 18 -901 17 -945 -1 -48 -21 -95 -64 -120 -111
-30 -57 -30 -155 0 -212 24 -44 79 -98 112 -109 20 -6 21 -14 21 -145 0 -154
7 -175 67 -186 26 -5 37 -1 57 19 25 25 26 30 26 157 l0 130 315 0 315 0 0
-607 0 -608 -843 843 c-794 793 -844 842 -877 842 -33 0 -103 -68 -1262 -1228z
m3112 1046 c27 -30 26 -73 -5 -103 l-24 -25 -414 0 c-404 0 -415 1 -441 21
-33 26 -36 79 -6 109 19 19 33 20 445 20 424 0 424 0 445 -22z"/>
<path d="M1912 3407 c-46 -49 -14 -127 52 -127 67 0 101 83 53 128 -31 29 -77
29 -105 -1z"/>
<path d="M3002 3407 c-46 -49 -12 -127 56 -127 42 0 72 31 72 73 0 68 -83 103
-128 54z"/>
<path d="M2560 3193 c-47 -18 -64 -84 -30 -121 43 -45 109 -34 130 24 22 56
-43 119 -100 97z"/>
<path d="M1793 3109 c-318 -53 -519 -370 -428 -675 45 -152 194 -224 330 -160
109 50 163 174 124 285 -28 80 90 137 137 66 9 -14 52 -120 96 -237 95 -253
97 -257 148 -300 73 -64 171 -76 258 -32 l52 26 52 -26 c87 -44 185 -32 258
32 52 43 56 53 152 306 43 116 85 221 94 233 18 29 57 38 93 23 39 -16 54 -54
41 -100 -23 -80 4 -184 61 -237 71 -65 180 -80 268 -37 99 50 145 149 146 314
0 121 -31 214 -108 315 -196 260 -598 285 -831 51 -71 -71 -115 -150 -177
-318 -24 -65 -46 -118 -49 -118 -3 0 -30 66 -60 146 -62 166 -108 243 -183
309 -132 116 -300 163 -474 134z m229 -169 c90 -34 90 -33 29 -94 -47 -48 -57
-53 -78 -45 -13 5 -46 9 -73 9 -49 0 -50 0 -90 59 -22 33 -40 64 -40 70 0 27
182 28 252 1z m1197 14 c17 -4 31 -11 31 -16 0 -5 -18 -36 -40 -69 -40 -59
-41 -59 -90 -59 -27 0 -60 -4 -73 -9 -21 -8 -31 -3 -78 45 -29 29 -51 55 -48
57 9 9 75 40 99 48 31 9 157 11 199 3z m-1550 -157 l41 -64 -22 -41 -23 -42
-77 0 c-91 0 -93 3 -54 86 19 41 80 124 91 124 1 0 21 -28 44 -63z m1815 -52
c14 -30 26 -63 26 -74 0 -19 -6 -21 -77 -21 l-78 0 -23 42 -22 41 41 64 42 64
32 -31 c18 -17 44 -56 59 -85z m-1234 -2 c12 -27 28 -64 35 -84 l13 -36 -57
-57 c-31 -31 -59 -56 -62 -56 -4 0 -21 39 -39 86 l-32 86 53 54 c29 30 56 54
60 54 4 0 17 -21 29 -47z m607 -5 c29 -29 53 -55 53 -59 0 -4 -14 -44 -31 -89
l-31 -82 -59 58 c-33 32 -59 65 -59 73 0 18 61 151 69 151 3 0 29 -23 58 -52z
m-1189 -275 c-5 -40 -54 -67 -97 -52 -26 9 -61 51 -61 74 0 3 36 5 81 5 l80 0
-3 -27z m1842 22 c0 -4 -7 -19 -15 -34 -36 -70 -145 -51 -145 25 0 11 18 14
80 14 44 0 80 -2 80 -5z m-1111 -123 c43 -114 41 -141 -10 -168 -57 -29 -96
-2 -132 92 l-23 58 61 57 c33 32 63 54 66 51 4 -4 20 -45 38 -90z m332 42 l57
-56 -24 -61 c-13 -33 -31 -68 -40 -78 -32 -36 -89 -31 -121 10 -19 25 -15 54
15 133 16 40 32 81 35 91 4 9 10 17 14 17 4 0 33 -25 64 -56z"/>
<path d="M971 2464 c-28 -36 -26 -66 5 -98 74 -73 175 28 106 107 -24 28 -86
23 -111 -9z"/>
<path d="M3785 2315 c-41 -40 -32 -99 18 -120 36 -15 82 -3 97 25 19 35 12 77
-16 99 -35 28 -69 26 -99 -4z"/>
<path d="M4112 2328 c-14 -7 -118 -176 -300 -486 -152 -262 -280 -485 -283
-496 -8 -28 9 -74 31 -86 10 -6 48 -10 84 -10 l66 0 -130 -260 c-71 -143 -130
-271 -130 -286 0 -73 37 -84 277 -84 l182 0 3 -284 3 -284 24 -26 24 -26 184
0 c182 0 184 0 208 25 l25 24 0 286 0 285 206 0 206 0 29 29 c16 16 29 39 29
50 0 12 -58 138 -130 281 -71 143 -130 262 -130 265 0 3 31 5 68 5 59 0 72 3
90 23 26 28 28 57 6 100 -62 121 -540 926 -560 945 -27 24 -47 26 -82 10z
m147 -411 c50 -87 91 -160 91 -161 0 -2 -29 -12 -64 -21 -107 -29 -344 -8
-334 29 2 6 45 85 97 174 76 132 96 161 106 150 7 -7 54 -84 104 -171z m-304
-329 c88 -29 234 -35 329 -13 43 9 93 23 112 31 32 13 34 13 48 -8 22 -35 106
-179 106 -182 0 -2 -28 -6 -62 -9 -51 -5 -67 -10 -85 -32 -13 -14 -23 -35 -23
-46 0 -10 59 -136 130 -279 72 -143 130 -262 130 -265 0 -3 -223 -5 -495 -5
l-495 0 130 260 c80 160 130 271 130 290 0 41 -30 67 -82 74 -24 3 -52 7 -64
8 -19 3 -16 12 40 106 45 76 64 101 76 96 8 -4 42 -15 75 -26z m275 -1198 l0
-230 -80 0 -80 0 0 230 0 230 80 0 80 0 0 -230z"/>
<path d="M1910 2160 c-28 -28 -26 -81 3 -108 49 -46 127 -12 127 54 0 63 -86
98 -130 54z"/>
<path d="M555 2086 c-139 -36 -249 -101 -352 -210 -165 -173 -226 -428 -158
-659 14 -49 35 -107 46 -129 69 -135 212 -270 343 -324 l45 -19 3 -285 c3
-270 4 -287 25 -325 12 -22 36 -54 55 -72 69 -67 -37 -63 1632 -63 1505 0
1512 0 1534 20 33 31 38 67 15 98 l-20 27 -176 3 -176 2 -3 498 c-4 476 -5
500 -26 577 -85 308 -305 530 -610 618 -102 29 -332 31 -432 3 -307 -84 -533
-311 -621 -622 -21 -76 -22 -99 -26 -576 l-4 -498 -356 0 -355 0 6 31 c3 17 6
150 6 295 l0 263 73 35 c130 63 256 187 322 316 49 96 70 188 70 305 0 130
-17 204 -74 320 -90 181 -246 310 -443 365 -85 23 -261 26 -343 6z m294 -152
c23 -7 23 -9 18 -83 -6 -88 -34 -174 -79 -243 l-31 -49 -37 70 c-55 104 -110
171 -195 238 l-40 31 30 13 c73 29 132 39 220 34 50 -2 101 -7 114 -11z m289
-182 c44 -58 71 -105 92 -166 l19 -56 -42 -21 c-98 -50 -246 -81 -310 -65
l-25 6 39 60 c22 32 51 85 63 117 21 50 30 86 52 201 l5 23 39 -31 c21 -17 52
-48 68 -68z m-668 -49 c40 -37 80 -87 98 -122 18 -32 32 -61 32 -63 0 -2 -38
-2 -84 0 -99 5 -196 -10 -279 -44 -33 -13 -61 -23 -62 -21 -1 1 4 34 12 72 17
81 60 167 116 230 l39 45 31 -17 c17 -10 61 -46 97 -80z m2268 -26 c108 -38
177 -82 267 -172 67 -66 89 -97 127 -175 75 -153 78 -178 78 -715 l0 -465
-700 0 -700 0 0 318 0 317 73 0 c64 0 80 4 123 30 32 20 59 48 79 81 l30 51
62 -5 c80 -6 139 15 187 68 49 54 66 101 66 179 l0 66 80 0 80 0 0 -145 c0
-132 2 -150 24 -196 68 -146 250 -178 367 -64 59 56 79 119 79 239 0 96 -12
130 -53 149 -32 15 -60 9 -87 -21 -17 -18 -20 -35 -20 -123 0 -87 -3 -105 -21
-128 -23 -30 -72 -35 -105 -12 -18 13 -19 32 -24 286 l-5 272 -28 24 c-53 45
-110 22 -128 -51 -13 -53 -39 -78 -79 -78 -40 0 -69 28 -76 75 -12 69 -76 98
-127 58 l-25 -20 -4 -191 -3 -191 -28 -24 c-31 -27 -45 -29 -81 -13 -24 11
-39 37 -50 92 -9 40 -63 64 -102 46 -43 -21 -54 -53 -54 -161 0 -85 -3 -101
-20 -118 -30 -30 -83 -27 -110 8 -20 24 -22 35 -17 103 13 164 84 316 207 439
86 86 193 148 312 180 109 30 305 24 406 -13z m-2178 -321 c0 -2 -18 -31 -41
-64 -47 -72 -96 -200 -105 -277 -4 -30 -10 -55 -14 -55 -4 0 -35 28 -69 62
-60 60 -121 163 -137 230 -5 24 -1 29 38 49 79 41 149 58 241 58 48 1 87 -1
87 -3z m685 -81 c-18 -80 -60 -163 -114 -225 l-38 -44 -51 33 c-65 41 -145
129 -178 192 -13 27 -24 51 -24 53 0 3 46 5 103 5 104 0 225 24 284 55 15 8
29 11 31 6 2 -4 -4 -38 -13 -75z m-549 -61 c12 -48 101 -169 174 -237 40 -37
71 -69 69 -71 -2 -2 -26 -12 -54 -21 -66 -23 -203 -31 -272 -15 l-55 12 7 71
c8 84 40 184 78 245 31 48 44 52 53 16z m94 -761 c0 -240 -1 -254 -20 -273
-30 -30 -83 -27 -109 6 -20 25 -21 39 -21 273 l0 246 75 0 75 0 0 -252z"/>
<path d="M3080 2080 c-30 -30 -27 -83 6 -109 35 -28 69 -26 99 4 50 49 20 125
-50 125 -22 0 -43 -8 -55 -20z"/>
<path d="M1550 2023 c-51 -19 -66 -81 -31 -124 13 -17 29 -23 56 -23 80 0 106
102 34 139 -30 16 -35 17 -59 8z"/>
<path d="M3392 1767 c-29 -31 -29 -77 1 -105 49 -46 127 -12 127 56 0 65 -83
97 -128 49z"/>
<path d="M1390 773 c-23 -8 -50 -46 -50 -70 0 -12 11 -34 23 -49 27 -31 74
-37 103 -14 67 56 6 163 -76 133z"/>
<path d="M1240 463 c-56 -21 -70 -96 -24 -132 15 -12 33 -21 41 -21 28 0 73
31 79 55 10 39 -4 73 -36 90 -31 16 -36 17 -60 8z"/>
<path d="M3570 457 c-56 -28 -59 -98 -6 -133 64 -41 149 47 103 107 -13 16
-50 39 -64 39 -4 0 -19 -6 -33 -13z"/>
</g>
    </Svg>
  );
};

export default Icon;
